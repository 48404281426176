import PropTypes from 'prop-types';
import navigationIcon from '../../assests/icons/navigation.svg';
import reloadIcon from '../../assests/icons/reload.svg';
import DebouncedTextEditor from '../Branding/DebouncedTextEditor';

const threeDotIcon = '⋮';

function RuleGroupAction({
  actionId,
  operationId,
  operation,
  selectedAction,
  action,
  isRenaming,
  setIsRenaming,
  handleActionClick,
  handleOperationNameChange,
  handleRemoveOperation,
  actionMenuOpen,
  setActionMenuOpen,
  actionMenuRef,
  renderOperationText,
}) {
  const operationType = Object.keys(operation)[0];
  const isOperationEditing = isRenaming === `${actionId}-${operationId}`;

  return (
    <div
      key={`${actionId}-${operationId}`}
      className={`rule-group__action ${selectedAction?.operationId === operationId ? 'rule-group__action--selected' : ''} ${selectedAction?.id === actionId && selectedAction?.operationId === null ? 'rule-group__action--active' : ''}`}
      onClick={() => handleActionClick({ id: actionId, ...action, operationId })}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          handleActionClick({ id: actionId, ...action, operationId });
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="rule-group__action-content">
        <img src={operationType === 'nextStep' ? navigationIcon : reloadIcon} alt="operation" />
        {isOperationEditing ? (
          <div
            className="action-name-editor"
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            role="textbox"
            tabIndex={0}
          >
            <DebouncedTextEditor
              text={operation.name || ''}
              onTextChange={(newText) => {
                handleOperationNameChange(actionId, operationId, newText);
                if (newText.trim() !== operation.name) {
                  setIsRenaming(null);
                }
              }}
            />
          </div>
        ) : (
          <span className="action-name">{operation.name || renderOperationText(operation)}</span>
        )}
      </div>

      <div className="action-menu" ref={actionMenuRef}>
        <button
          type="button"
          className={`three-dot-button ${actionMenuOpen === `${actionId}-${operationId}` ? 'visible' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            setActionMenuOpen(actionMenuOpen === `${actionId}-${operationId}` ? null : `${actionId}-${operationId}`);
          }}
        >
          <span className="icon-text">{threeDotIcon}</span>
        </button>

        {actionMenuOpen === `${actionId}-${operationId}` && (
        <div className="action-dropdown-menu">
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setIsRenaming(`${actionId}-${operationId}`);
              setActionMenuOpen(null);
            }}
          >
            Rename
          </button>
          <button
            type="button"
            className="remove-button"
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveOperation(actionId, operationId);
              setActionMenuOpen(null);
            }}
          >
            Remove
          </button>
        </div>
        )}
      </div>
    </div>
  );
}

RuleGroupAction.propTypes = {
  actionId: PropTypes.string.isRequired,
  operationId: PropTypes.string.isRequired,
  operation: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired,
  selectedAction: PropTypes.object,
  isRenaming: PropTypes.string,
  setIsRenaming: PropTypes.func.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  handleOperationNameChange: PropTypes.func.isRequired,
  handleRemoveOperation: PropTypes.func.isRequired,
  actionMenuOpen: PropTypes.string,
  setActionMenuOpen: PropTypes.func.isRequired,
  actionMenuRef: PropTypes.object.isRequired,
  renderOperationText: PropTypes.func.isRequired,
};

RuleGroupAction.defaultProps = {
  selectedAction: null,
  isRenaming: null,
  actionMenuOpen: null,
};

export default RuleGroupAction;
