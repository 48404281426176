import PropTypes from 'prop-types';
import RuleGroup from './RuleGroup';
import StandaloneAction from './StandaloneAction';

function ActionsRenderer({
  actions,
  isDragging,
  dragOverId,
  selectedAction,
  handleDragStart,
  handleDrop,
  setDragOverId,
  setIsDragging,
  handleRuleNameChange,
  handleRemoveAction,
  handleActionClick,
  handleOperationNameChange,
  handleRemoveOperation,
  handleAddOperation,
  handleActionNameChange,
  setIsRenaming,
  isRenaming,
}) {
  return (
    <>
      {Object.entries(actions).map(([actionId, action]) => {
        if (action.rule) {
          return (
            <RuleGroup
              key={actionId}
              actionId={actionId}
              action={action}
              isDragging={isDragging}
              dragOverId={dragOverId}
              selectedAction={selectedAction}
              handleDragStart={handleDragStart}
              handleDrop={handleDrop}
              setDragOverId={setDragOverId}
              setIsDragging={setIsDragging}
              handleRuleNameChange={handleRuleNameChange}
              handleRemoveAction={handleRemoveAction}
              handleActionClick={handleActionClick}
              handleOperationNameChange={handleOperationNameChange}
              handleRemoveOperation={handleRemoveOperation}
              handleAddOperation={handleAddOperation}
            />
          );
        }
        return (
          <StandaloneAction
            key={actionId}
            actionId={actionId}
            action={action}
            isRenaming={isRenaming}
            dragOverId={dragOverId}
            setDragOverId={setDragOverId}
            selectedAction={selectedAction}
            handleDragStart={handleDragStart}
            handleDrop={handleDrop}
            handleActionClick={handleActionClick}
            handleActionNameChange={handleActionNameChange}
            setIsRenaming={setIsRenaming}
            handleRemoveAction={handleRemoveAction}
          />
        );
      })}
    </>
  );
}

ActionsRenderer.propTypes = {
  actions: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  dragOverId: PropTypes.string,
  selectedAction: PropTypes.string,
  handleDragStart: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  setDragOverId: PropTypes.func.isRequired,
  setIsDragging: PropTypes.func.isRequired,
  handleRuleNameChange: PropTypes.func.isRequired,
  handleRemoveAction: PropTypes.func.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  handleOperationNameChange: PropTypes.func.isRequired,
  handleRemoveOperation: PropTypes.func.isRequired,
  handleAddOperation: PropTypes.func.isRequired,
  handleActionNameChange: PropTypes.func.isRequired,
  setIsRenaming: PropTypes.func.isRequired,
  isRenaming: PropTypes.bool.isRequired,
};

ActionsRenderer.defaultProps = {
  dragOverId: null,
  selectedAction: null,
};

export default ActionsRenderer;
