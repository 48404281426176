import HVError, { errorCodes } from '../utils/error';
import getDemoToken from '../utils/getDemoToken';

const logBatchedEvents = async (
  {
    eventQueue,
    demoToken,
    appId,
    updateTokenInStore,
    retryCount = 0,
  },
) => {
  const finalArrayOfEventsToSend = eventQueue.map((event) => ({
    project: process.env.REACT_APP_APOLLO_PROJECT_NAME || 'workflow-builder-events',
    properties: event,
  }));

  const url =
  process.env.REACT_APP_APOLLO_EVENT_TRACKING_URL ||
  'https://ind-thomas.hyperverge.co/v1/apollo-batch-prod-sdk-wrapper';

  const headers = {
    'Content-Type': 'application/json',
    Authorization: demoToken,
  };
  const response = await fetch(url, {
    keepalive: true,
    method: 'POST',
    headers,
    body: JSON.stringify({ data: finalArrayOfEventsToSend }),
  });

  if (!response.ok) {
    const maxRetries = process.env.REACT_APP_EVENT_BATCH_MAX_RETRIES || 1;
    if (retryCount >= maxRetries) {
      // if max retries reached, throw error
      const originalError = new Error('Error sending event');
      originalError.response = response;
      throw new HVError({
        errorCode: errorCodes.errorLoggingEvent,
        message: 'Error logging event',
        originalError,
        debugInfo: {
          droppedEventCount: eventQueue.length,
          retryCount,
        },
      });
    }

    // if demo token is invalid, fetch a new one
    let updatedDemoToken = demoToken;
    if (response.status === 401) {
      updatedDemoToken = await getDemoToken(appId);
      updateTokenInStore(updatedDemoToken);
    }
    await logBatchedEvents({
      eventQueue,
      demoToken: updatedDemoToken,
      appId,
      updateTokenInStore,
      retryCount: retryCount + 1,
    });
  }
};

export default logBatchedEvents;
