import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { cloneDeep } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  updateSelectedScreenState,
  updateSelectedModuleState,
  updateSelectedModuleSubType,
  updateSelectedModuleType,
  updateCloseBrandingWindow,
  selectSelectedModuleSubType,
  updateCloseSDK,
  selectSelectedModuleType,
  updateBrandingElements,
  selectSelectedModuleState,
  selectCloseSDK,
  selectDefaultTextConfig,
  updateSelectedLanguage,
  selectSelectedLanguage,
  updateCustomTextConfig,
  selectCustomTextConfig,
  selectFontStyleSheets,
  selectCustomUIConfig,
  updateFontStyleSheets,
} from '../../reducers/editBranding';
import { copyComponentDataToClipboard } from '../FormModule/formOperations';
import {
  selectReloadSdk, updateSelectedComponentPath, selectedComponentPath,
} from '../../reducers/dynamicForm';
import './EditBrandingContainer.scss';
import BrandingNavBar from '../../components/Branding/BrandingNavBar';
import ShowListOfModuleScreens from '../../components/Branding/ShowListOfModuleScreens';
import DisplaySDKScreen from '../../components/Branding/DisplaySDKScreen';
import ShowListOfElements from '../../components/Branding/ShowListOfElements';
import { closeExistingSDK } from '../../components/utils';
import EditSDKBranding from './EditSdkBranding/EditSdkBranding';
import EditIndividualScreens from './EditIndividualScreens';
import FormModuleDrawer from '../FormModule/FormModuleDrawer';
import {
  selectFormV2Components,
  selectOrderOfNodes, selectSelectedNode, selectSelectedWorkflow, selectVersionedModules,
} from '../../reducers/workflow';
import useApiHooks from '../../hooks/api/useApiHooks';
import { getGroupedListOfModules } from '../../utils/editBrandingUtils';
import languageCodes from '../../components/Branding/utils/languageCodeMapping';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import { workflowOperationsObj } from '../../workflowOperations';
import { getSelectedModule } from '../../components/ViewWorkflow/InputsToModule/utils/updateWorkflow';
import withDeletionDependencyCheck from '../../utils/withDeletionDependencyCheck';
import { defaultEditConfiguration, defaultEditConfigurationForSuperModules } from './constants';
import {
  getConditionalVariables, getModuleOutputs, getPredefinedValues, getWorkflowInputVariables,
} from '../../components/ViewWorkflow/InputsToModule/utils';
import { compileUiConfig } from '../uiConfigOperations';
import { formComponentList as formComponentsConfig } from '../../constants/dynamicFormComponents';
import FormModuleDrawerV2 from '../FormModule/FormModuleV2Drawer';
import {
  copyFormComponentDataToClipBoardInV2,
  getFormV2ComponentFromClipboard,
  getDynamicFormComponentFromClipboard,
  getModuleFromId,
  getFormComponents,
  getComponentFromPath,
} from '../FormModule/helper';
import useShowErrorAlert, { useShowCustomAlert } from '../../hooks/custom/useCustomAlert';
import { copyOperations } from '../../workflowOperations/workflowOperation';
import { refreshComponentIds } from '../../components/FormModule/utils';
import doesComponentHaveChildrenNodes from '../../utils/doesComponentHaveChildrenNodes';
import {
  handleOnAdd,
  handleOnCopy,
  handleOnDelete,
  handleOnDrag,
  handleOnFormComponentChange,
  handleOnFormPropertyChange,
  handleOnSubtypeChange,
} from './helper';
import GenericConfirmationModal from '../../components/ViewWorkflow/GenericConfirmationModal/GenericConfirmationModal';
import getMatchedModuleAndConditionNames from '../../utils/getMatchedModulesAndConditionsNames';
import formatAsBulletPoints from '../../utils/formatAsBulletPoints';
import ErrorHandlerContext from '../../context/ErrorHandlerContext';
import { generateRefreshMap } from './utils';

function EditBrandingContainer(props) {
  const showErrorAlert = useShowErrorAlert();
  const { workflowId, checkDependencies: checkDeletionDependencies } = props;
  const dispatch = useDispatch();
  const {
    pathArray: selectedComponentPathArray,
  } = useSelector(selectedComponentPath);
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const versionedModules = useSelector(selectVersionedModules);
  const formV2Components = useSelector(selectFormV2Components);
  const selectedNode = useSelector(selectSelectedNode);
  const uiConfig = useSelector(selectCustomUIConfig);
  const textConfig = useSelector(selectCustomTextConfig);
  const defaultConfigs = useSelector(selectDefaultTextConfig);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const supportedScreens = useSelector((state) => state.editBranding.screens);
  const selectedModuleType = useSelector(selectSelectedModuleType);
  const selectedModuleSubType = useSelector(selectSelectedModuleSubType);
  const brandingElements = useSelector((state) => state.editBranding.brandingElements);
  const selectedScreen = useSelector((state) => state.editBranding.selectedScreenState);
  const selectedModuleId = useSelector(selectSelectedModuleState);
  const orderOfNodes = useSelector(selectOrderOfNodes);
  const showCustomAlert = useShowCustomAlert();

  const jwtToken = useSelector((state) => state.user.appIdKeyToken);
  const customStyleSheetsObj = useSelector(selectFontStyleSheets);
  const customStyleSheets = Object.values(customStyleSheetsObj);
  const reloadSdk = useSelector(selectReloadSdk);
  const closeSdk = useSelector(selectCloseSDK);

  const [selectedElementIndex, setSelectedElementIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(2);
  const [closePopup, setClosePopup] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deletionResults, setDeletionResults] = useState(null);
  const [deletionData, setDeletionData] = useState(null);
  // TODO: Move this state inside ShowListOfModulesSuper
  const [screensToDisplay, setScreensToDisplay] = useState([]);
  const handleError = useContext(ErrorHandlerContext);

  // TODO: Move to page
  const navigate = useNavigate();
  const selectedElement = useMemo(
    () => brandingElements[selectedElementIndex] || {},
    [brandingElements, selectedElementIndex],
  );

  const { fetchCustomTextConfig } = useApiHooks();

  const [refreshMap, setRefreshMap] = useState({});

  useEffect(() => {
    const newRefreshMap = generateRefreshMap(formV2Components);
    setRefreshMap(newRefreshMap);
  }, [formV2Components]);

  const languagesSupported = useMemo(() => {
    const textConfigKeys = Object.keys(defaultConfigs || {})
      .filter((configName) => configName.endsWith('text_config') && languageCodes[configName.split('_')[1] || '']);
    return textConfigKeys.map((key) => {
      const languageCode = key.split('_')[1] || '';
      return {
        id: languageCode,
        name: languageCodes[languageCode],
        value: languageCode,
      };
    });
  }, [defaultConfigs]);

  const onLanguageChange = (value) => {
    dispatch(updateSelectedLanguage({ language: value }));
  };
  const cancelDeleteActionFromGenericConfirmationModal = () => {
    setConfirmDelete(false);
    setDeletionResults(null);
  };
  const confirmDeleteActionFromGenericConfirmationModal = () => {
    handleOnDelete(deletionData);
    setConfirmDelete(false);
    setDeletionResults(null);
  };

  const handleClick = (tabNumber) => {
    if (activeTab !== tabNumber) {
      setActiveTab(tabNumber);
    }
  };

  const updateScreenName = useCallback(
    (module) => {
      setScreensToDisplay((oldScreens) => {
        if (oldScreens.includes(module)) {
          return oldScreens.filter((screen) => screen !== module);
        }
        return [...oldScreens, module];
      });
    },
    [setScreensToDisplay],
  );

  const handleScreenSelect = useCallback(
    (screen, module) => {
      dispatch(updateSelectedModuleState({ module }));
      dispatch(updateSelectedModuleSubType({ module }));
      dispatch(updateSelectedModuleType({ module }));
      dispatch(updateSelectedScreenState({ screen }));
    },
    [dispatch],
  );

  // Set modules to be displayed in UI screens
  const modulesAndCompiledWorkflow = useMemo(() => {
    const { listOfListOfModules, workflow } = getGroupedListOfModules(
      selectedWorkflow,
      versionedModules,
      supportedScreens,
    );
    return { listOfListOfModules, workflow };
  /* selectedWorkflow can change from any children of EditBrandingContainer,
     but we don't want to recalculate modulesInWorkflow to avoid re-triggers,
     hence it should be skipped from the deps array */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionedModules, supportedScreens, JSON.stringify(selectedWorkflow)]);

  const getCurrentSelectedModuleData = (highLevelWorkflow, compiledWorkflow, moduleId) => {
    if (!moduleId || !highLevelWorkflow || !compiledWorkflow) return null;
    const moduleFromDecompiledWorkflow = getSelectedModule(highLevelWorkflow, moduleId);
    const moduleData = {
      module: moduleFromDecompiledWorkflow,
      subType: '',
      version: 'v1',
      isFormPartOfSuperModule: false,
      superModuleId: '',
      mappingId: '',
    };

    if (!moduleFromDecompiledWorkflow) {
      const moduleFromCompiledWorkflow = getSelectedModule(compiledWorkflow, moduleId);
      if (moduleFromCompiledWorkflow) {
        const { superModuleId, superModuleType, mappingId } = moduleFromCompiledWorkflow;
        const superModule = getSelectedModule(highLevelWorkflow, superModuleId);
        moduleData.isFormPartOfSuperModule = true;
        moduleData.superModuleId = superModuleId;
        moduleData.subType = superModuleType;
        moduleData.mappingId = mappingId;
        moduleData.version = superModule?.version || 'v1';
        moduleData.module = moduleFromCompiledWorkflow;
      }
    }
    return moduleData;
  };

  const [selectedModuleData, setSelectedModuleData] = useState(getCurrentSelectedModuleData(
    selectedWorkflow,
    modulesAndCompiledWorkflow.workflow,
    selectedModuleId,
  ));

  useEffect(() => {
    const moduleData = getCurrentSelectedModuleData(
      selectedWorkflow,
      modulesAndCompiledWorkflow.workflow,
      selectedModuleId,
    );
    setSelectedModuleData(moduleData);
  }, [JSON.stringify(selectedWorkflow),
    selectedModuleId,
    JSON.stringify(modulesAndCompiledWorkflow),
  ]);

  const getEditConfigurations = (moduleData) => {
    if (moduleData?.isFormPartOfSuperModule) return defaultEditConfigurationForSuperModules;
    return defaultEditConfiguration;
  };

  const [editConfigurations, setEditConfigurations] = useState(
    getEditConfigurations(selectedModuleData),
  );

  useEffect(() => {
    setEditConfigurations(
      getEditConfigurations(selectedModuleData),
    );
  }, [JSON.stringify(selectedModuleData)]);

  useEffect(() => {
    if (textConfig && Object.keys(textConfig).length) return;

    fetchCustomTextConfig(workflowId, selectedLanguage)
      .then((res) => {
        try {
          updateWorkflowInState({}, true, {
            operation: workflowOperationsObj.SET_WORKFLOW_ATTRIBUTE,
            actionData: {
              path: `properties.textConfigSource.${selectedLanguage}`,
              value: Object.keys(res || {}).length ? 'custom' : 'default',
            },
          });
          dispatch(updateCustomTextConfig({ textConfig: res, language: selectedLanguage }));
        } catch (error) {
          handleError(error);
        }
      }).catch(() => {
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage, workflowId]);

  useEffect(() => {
    dispatch(updateCloseSDK({ closeSDK: false }));

    return () => {
      dispatch(updateCloseSDK({ closeSDK: true }));
    };
  }, [dispatch]);

  const onSetSelectedElement = (index) => {
    setSelectedElementIndex(index);
  };
  // Runs once on mount
  useEffect(() => {
    const currentModule =
      modulesAndCompiledWorkflow.listOfListOfModules.find(
        (module) => module.id === selectedNode?.id,
      ) || modulesAndCompiledWorkflow.listOfListOfModules[0];
    updateScreenName(currentModule);

    const currentModuleType = currentModule?.modules[0].moduleType || 'NA';
    const currentScreen = supportedScreens?.[currentModuleType]?.[0].state || supportedScreens?.[currentModuleType]?.[0].name || '';
    handleScreenSelect(currentScreen, currentModule?.modules[0] || { id: '', nodeType: '' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedNode?.id, updateScreenName, supportedScreens, handleScreenSelect,
  ]);

  // Update brandingElements when selectedModuleType, selectedScreen change
  useEffect(() => {
    const selectedScreenBrandingConfig = supportedScreens[selectedModuleType]?.find(
      (screens) => screens.state === selectedScreen,
    );
    if (selectedScreenBrandingConfig?.brandingKeys) {
      const { brandingKeys } = selectedScreenBrandingConfig;
      dispatch(updateBrandingElements({ brandingElements: brandingKeys }));
    } else {
      dispatch(updateBrandingElements({ brandingElements: [] }));
    }
    setSelectedElementIndex(0);
  }, [selectedModuleType, selectedScreen, dispatch, supportedScreens]);

  const onElementUpdate = (element) => {
    const newBrandingKeys = cloneDeep(brandingElements);
    newBrandingKeys[selectedElementIndex] = element;
    dispatch(updateBrandingElements({ brandingElements: newBrandingKeys }));
  };

  const handleSave = async () => {
    dispatch(updateCloseBrandingWindow({ closeBrandingWindow: true }));
    // launchId is not required in this case
    closeExistingSDK({ launchId: null, closeAllSDKs: false });
    dispatch(updateCloseSDK({ closeSDK: true }));
    setClosePopup(true);
    navigate(`/view?id=${workflowId}`);
  };

  const handleOnClick = (pathArray, rootPath) => {
    dispatch(updateSelectedComponentPath({ pathArray, basePath: rootPath }));
  };

  const addNewFontURL = (fontName) => {
    const url = process.env.REACT_APP_CUSTOM_FONT_URL.replace('<SELECTED_FONT>', fontName);
    dispatch(updateFontStyleSheets({ fontStyleSheets: { [fontName]: url } }));
  };

  const renderSDKBranding = () => (
    activeTab === 1 ? <EditSDKBranding /> : null
  );

  const handleDeleteComponentFormV2 = (componentId, moduleId) => {
    try {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.DELETE_FORM_COMPONENT_V2,
        actionData: {
          targetNodeId: moduleId,
          targetComponentId: componentId,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleAddComponentFormV2 = (componentId, moduleId) => {
    try {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.ADD_FORM_COMPONENT_V2,
        actionData: {
          targetNodeId: moduleId,
          targetComponentId: componentId,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleCloneComponentFormV2 = (componentId, moduleId) => {
    try {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.CLONE_FORM_COMPONENT_V2,
        actionData: {
          targetNodeId: moduleId,
          targetComponentId: componentId,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleUpdateComponentTagNameFormV2 = (
    componentId,
    moduleId,
    tagName,
    libraryUrl,
    styleSheetsUrl,
    defaults,
    componentRef,
  ) => {
    const {
      attributes: defaultAttributes = {},
      properties: defaultProperties = {},
      styles: defaultStyles = {},
    } = defaults;
    const {
      libraryId = '',
      formComponentId = '',
      libraryName = '',
      componentLabel = '',
    } = componentRef;

    try {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.UPDATE_TAG_IN_FORM_COMPONENT_V2,
        actionData: {
          targetNodeId: moduleId,
          targetComponentId: componentId,
          tagName,
          libraryUrl,
          styleSheetsUrl,
          defaultAttributes,
          defaultProperties,
          defaultStyles,
          libraryId,
          formComponentId,
          libraryName,
          componentLabel,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };
  const onDeleteHandler = ({
    componentId,
    rootPath,
    moduleId,
    checkDepsFn,
    currCustomUiConfig,
    workflow,
    selectedComponentPathArray: selectedPathArray,
  }) => {
    const hasChildrenNodes =
    doesComponentHaveChildrenNodes(workflow, moduleId, componentId, rootPath);
    const matchedNames = getMatchedModuleAndConditionNames(workflow, hasChildrenNodes);
    const updatedNames = matchedNames.map((item, index) => ({
      key: `item-${index}`,
      value: item,
    }));
    if (matchedNames.length > 0) {
      setDeletionResults(updatedNames);
      setConfirmDelete(true);
      setDeletionData({
        componentId,
        rootPath,
        moduleId,
        checkDepsFn,
        currCustomUiConfig,
        workflow,
        selectedComponentPathArray: selectedPathArray,
      });
    } else {
      try {
        handleOnDelete({
          componentId,
          rootPath,
          moduleId,
          workflow,
          selectedComponentPathArray: selectedPathArray,
        });
      } catch (err) {
        handleError(err);
      }
    }
  };

  const handleDragComponentFormV2 = (
    pickComponentId,
    dropComponentId,
    moduleId,
  ) => {
    try {
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.DRAG_FORM_COMPONENT_V2,
        actionData: {
          targetNodeId: moduleId,
          pickComponentId,
          dropComponentId,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };
  const handlePasteInDynamicForm = async (workflow, moduleId, rootPath) => {
    try {
      // Retrieve the component data from the clipboard
      const { copiedComponent, uiConfigData: copiedUiConfig } =
      await getDynamicFormComponentFromClipboard();
      const selectedModule = getModuleFromId(workflow, moduleId);
      const { component: updatedComponent, originalToClonedComponentIdMap } = refreshComponentIds(
        copiedComponent,
        selectedModule,
        formComponentsConfig,
      );
      const updatedCopiedUiConfig = copiedUiConfig ?? null;
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.ADD_FORM_COMPONENT_VIA_CLIPBOARD,
        actionData: {
          moduleId,
          updatedComponent,
          rootPath: rootPath.rootPath,
          originalToClonedComponentIdMap,
          copiedUiConfig: updatedCopiedUiConfig,
        },
      });
      showCustomAlert({
        alertSeverity: 'success',
        message: 'Copied form UI has been pasted - please configure the logic/rules/validation now.',
      });
    } catch (error) {
      showErrorAlert({ error, message: error?.message });
    }
  };

  const handleOnCopyToClipboard = async ({
    componentId,
    rootPath,
    moduleId,
    workflow,
  }) => {
    const pathArray = copyOperations(workflow, moduleId, rootPath, componentId);
    const selectedModule = getModuleFromId(workflow, moduleId);
    const formComponents = getFormComponents(selectedModule, rootPath);
    const component = getComponentFromPath(formComponents, pathArray);
    try {
      await copyComponentDataToClipboard(
        component,
        moduleId,
        uiConfig,
      );
      showCustomAlert({
        alertSeverity: 'success',
        message: 'Form UI has been copied - you can paste it in this workflow or in other workflows.',
      });
      // Inform the user that the component has been copied
    } catch (error) {
      // Handle error
      showErrorAlert({ error, message: error?.message });
    }
  };

  const handlePropertyUpdateFormV2 = ({
    moduleId,
    componentId,
    property,
    value,
    isFormPartOfSuperModule,
    superModuleId,
    mappingId,
  }) => {
    if (isFormPartOfSuperModule === true) {
      // UNSET operation is not supported for super module edits
      if (value !== null) {
        try {
          updateWorkflowInState({}, true, {
            operation: workflowOperationsObj.UPDATE_FORM_V2_PROPERTY_IN_SUPER_MODULE,
            actionData: {
              superModuleId,
              mappingId,
              componentId,
              key: property,
              value,
            },
          });
        } catch (error) {
          handleError(error);
        }
      }
    } else {
      const path = `componentConfigs['${componentId}'].${property}`;
      if (value === null) {
        try {
          updateWorkflowInState({}, true, {
            operation: workflowOperationsObj.UNSET_MODULE_PROPERTY,
            actionData: {
              targetNodeId: moduleId,
              workflowKey: path,
            },
          });
        } catch (error) {
          handleError(error);
        }
      } else {
        try {
          updateWorkflowInState({}, true, {
            operation: workflowOperationsObj.SET_MODULE_PROPERTY,
            actionData: {
              targetNodeId: moduleId,
              workflowKey: path,
              value,
              moduleConfig: {},
            },
          });
        } catch (error) {
          handleError(error);
        }
      }
    }
  };

  const handleCopyComponentFormV2 = async (module, componentId) => {
    try {
      await copyFormComponentDataToClipBoardInV2(module, componentId);
    } catch (error) {
      showErrorAlert({ error, message: error?.message });
    }
  };

  const handlePasteInFormV2 = async (moduleId) => {
    try {
      const { html, componentConfigsFromContextCopy } = await getFormV2ComponentFromClipboard();
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.ADD_FORM_COMPONENT_V2_VIA_CLIPBOARD,
        actionData: {
          targetNodeId: moduleId,
          htmlString: html,
          componentConfigsFromContextCopy,
        },
      });
    } catch (error) {
      showErrorAlert({ error, message: error?.message });
    }
  };

  const renderFormModuleDrawer = () => (
    (activeTab === 2 && selectedModuleSubType === 'form' && selectedModuleData?.module) ? (
      <FormModuleDrawer
        editConfigurations={editConfigurations}
        formModule={selectedModuleData.module}
        onClickComponent={handleOnClick}
        onDragComponent={(fromComponentObj, toComponentObj) => {
          if (!selectedModuleData?.isFormPartOfSuperModule) {
            handleOnDrag({
              fromComponentObj,
              toComponentObj,
              moduleId: selectedModuleData?.module?.id,
            });
          }
        }}
        onAddComponent={(componentId, rootPath) => handleOnAdd({
          componentId,
          rootPath,
          moduleId: selectedModuleData?.module?.id,
          workflow: selectedModuleData?.isFormPartOfSuperModule ?
            modulesAndCompiledWorkflow.workflow : selectedWorkflow,
        })}
        onDeleteComponent={(componentId, rootPath, currCustomUiConfig) => onDeleteHandler({
          componentId,
          rootPath,
          moduleId: selectedModuleData?.module?.id,
          checkDepsFn: checkDeletionDependencies,
          currCustomUiConfig,
          workflow: selectedModuleData?.isFormPartOfSuperModule ?
            modulesAndCompiledWorkflow.workflow : selectedWorkflow,
          selectedComponentPathArray,
        })}
        onUpdateComponent={(
          pathArray,
          rootPath,
          newComponent,
        ) => {
          if (!selectedModuleData?.isFormPartOfSuperModule) {
            handleOnFormComponentChange({
              pathArray,
              rootPath,
              moduleId: selectedModuleData?.module?.id,
              workflow: selectedModuleData?.isFormPartOfSuperModule ?
                modulesAndCompiledWorkflow.workflow : selectedWorkflow,
              newComponent,
            });
          }
        }}
        onSubtypeChange={(
          pathArray,
          rootPath,
          moduleId,
          newSubtype,
        ) => {
          if (!selectedModuleData?.isFormPartOfSuperModule) {
            handleOnSubtypeChange({
              pathArray,
              rootPath,
              moduleId,
              newSubtype,
            });
          }
        }}
        onFormPropertyUpdate={({
          key,
          value,
          moduleId,
          componentId,
          isUIProperty,
        }) => handleOnFormPropertyChange({
          key,
          value,
          moduleId,
          componentId,
          isUIProperty,
          isFormPartOfSuperModule: selectedModuleData?.isFormPartOfSuperModule,
          superModuleId: selectedModuleData?.superModuleId,
          mappingId: selectedModuleData?.mappingId,
        })}
        onCopyComponent={(componentId, rootPath) => handleOnCopy({
          componentId,
          rootPath,
          moduleId: selectedModuleData?.module?.id,
          workflow: selectedModuleData?.isFormPartOfSuperModule ?
            modulesAndCompiledWorkflow.workflow : selectedWorkflow,
        })}
        onCopyComponentToClipboard={(componentId, rootPath) => handleOnCopyToClipboard({
          componentId,
          rootPath,
          moduleId: selectedModuleData?.module?.id,
          workflow: selectedModuleData?.isFormPartOfSuperModule ?
            modulesAndCompiledWorkflow.workflow : selectedWorkflow,
        })}
        onAddFontUrl={addNewFontURL}
        moduleOutputs={getModuleOutputs(
          orderOfNodes,
          selectedNode?.id,
          selectedWorkflow,
          formComponentsConfig,
          versionedModules,
        )}
        conditionalVariables={getConditionalVariables(selectedWorkflow)}
        workflowInputs={getWorkflowInputVariables(selectedWorkflow)}
        preDefinedValues={getPredefinedValues(selectedWorkflow, formComponentsConfig)}
        uiConfig={compileUiConfig(uiConfig, modulesAndCompiledWorkflow.workflow)}
        onPasteComponent={(rootPath) => handlePasteInDynamicForm(
          selectedWorkflow,
          selectedModuleData?.module?.id,
          rootPath,
        )}
        moduleBuilderProperties={selectedWorkflow?.properties?.moduleBuilder?.builderProperties
          || {}}
      />
    ) : null
  );

  const renderFormModuleDrawerV2 = () => (
    (activeTab === 2 && selectedModuleData?.module?.type === 'dynamicFormV2') ? (
      <FormModuleDrawerV2
        formModule={selectedModuleData.module}
        formV2Components={formV2Components}
        refreshMap={refreshMap}
        onAddComponent={(componentId) => handleAddComponentFormV2(
          componentId,
          selectedModuleData?.module?.id,
        )}
        onDeleteComponent={(componentId) => handleDeleteComponentFormV2(
          componentId,
          selectedModuleData?.module?.id,
        )}
        onCloneComponent={(componentId) => handleCloneComponentFormV2(
          componentId,
          selectedModuleData?.module?.id,
        )}
        onCopyComponent={(componentId) => handleCopyComponentFormV2(
          selectedModuleData?.module,
          componentId,
        )}
        onUpdateComponentTagName={(
          componentId,
          tagName,
          libraryUrl,
          styleSheetsUrl,
          defaults,
          componentRef,
        ) => handleUpdateComponentTagNameFormV2(
          componentId,
          selectedModuleData?.module?.id,
          tagName,
          libraryUrl,
          styleSheetsUrl,
          defaults,
          componentRef,
        )}
        onDragComponent={(pickComponentId, dropComponentId) => handleDragComponentFormV2(
          pickComponentId,
          dropComponentId,
          selectedModuleData?.module?.id,
        )}
        onUpdateComponent={({
          componentId, property, value,
        }) => handlePropertyUpdateFormV2({
          moduleId: selectedModuleData?.module?.id,
          componentId,
          property,
          value,
          isFormPartOfSuperModule: selectedModuleData?.isFormPartOfSuperModule,
          superModuleId: selectedModuleData?.superModuleId,
          mappingId: selectedModuleData?.mappingId,
        })}
        onPasteComponent={() => handlePasteInFormV2(
          selectedModuleData?.module?.id,
        )}
        isFormPartOfSuperModule={selectedModuleData?.isFormPartOfSuperModule}
      />
    ) : null
  );

  const renderOtherUIElements = () => {
    if (activeTab === 2 && selectedModuleSubType !== 'form' && selectedModuleData?.module?.type !== 'dynamicFormV2') {
      return selectedElement &&
        Object.keys(selectedElement) &&
        brandingElements.length > 0 &&
        brandingElements.find((elem) => Boolean(elem.textConfig)) ? (
          <Grid container>
            <Grid item xs={5}>
              <ShowListOfElements
                selectedElement={selectedElement}
                brandingElements={brandingElements}
                setSelectedElement={onSetSelectedElement}
                selectedModuleId={selectedModuleId}
                selectedWorkflow={selectedWorkflow}
              />
            </Grid>
            <Grid item xs={7} alignItems="center">
              {selectedElement && Object.keys(selectedElement) && (
                <EditIndividualScreens
                  selectedElement={selectedElement}
                  onElementUpdate={onElementUpdate}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <div className="edit-branding-popup__no-content">Nothing to edit here!</div>
        );
    }
    return null;
  };

  return closePopup === false ? (
    <div className="edit-branding-popup-background">
      <div className="edit-branding-popup">
        <BrandingNavBar
          activeTab={activeTab}
          handleClick={handleClick}
          isSaving={false}
          handleSave={handleSave}
          languagesSupported={languagesSupported}
          onLanguageChange={onLanguageChange}
          selectedLanguage={languageCodes[selectedLanguage || 'en']}
        />
        <div className="edit-branding-popup__body">
          <ShowListOfModuleScreens
            modulesInWorkflow={modulesAndCompiledWorkflow.listOfListOfModules}
            screensToDisplay={screensToDisplay}
            supportedScreens={supportedScreens}
            updateScreenNames={updateScreenName}
            handleScreenSelect={handleScreenSelect}
            selectedScreen={selectedScreen}
            selectedModuleType={selectedModuleType}
            selectedModuleId={selectedModuleId}
          />
          <div className="sdk-screen">
            <DisplaySDKScreen
              versionedModules={versionedModules}
              selectedWorkflow={modulesAndCompiledWorkflow.workflow}
              screenToTeleport={selectedScreen}
              uiConfig={uiConfig}
              textConfig={textConfig}
              defaultTextConfig={defaultConfigs[`default_${selectedLanguage}_text_config`]}
              moduleToTeleport={selectedModuleId}
              jwtToken={jwtToken}
              customStyleSheets={customStyleSheets}
              reloadSdk={reloadSdk}
              closeSdk={closeSdk}
              selectedLanguage={selectedLanguage}
              selectedElement={selectedElement}
              workflowId={workflowId}
              isScreenEditsTab={activeTab === 2}
              isForm={['dynamicForm', 'dynamicFormV2'].includes(selectedModuleType)}
            />
          </div>
          <div className={activeTab === 2 ? 'edit-branding-popup__body__settings' : 'edit-branding-popup__body__configuration'}>
            {renderSDKBranding()}
            {renderFormModuleDrawer()}
            {renderFormModuleDrawerV2()}
            {renderOtherUIElements()}
            {confirmDelete && (
            <GenericConfirmationModal
              titleText="Delete Component"
              descriptionTextArr={[
                {
                  id: 'static_warning',
                  text: 'This button is connected to the following nodes. Deleting this button will remove these nodes. Proceed?',
                },
                {
                  id: 'deletion_results',
                  text: formatAsBulletPoints(deletionResults), // Use the function to format results
                },
              ]}
              cancelBtnText="Cancel"
              confirmBtnText="Confirm"
              onCancelClick={cancelDeleteActionFromGenericConfirmationModal}
              onConfirmClick={confirmDeleteActionFromGenericConfirmationModal}
            />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
}

EditBrandingContainer.propTypes = {
  checkDependencies: PropTypes.func.isRequired,
  workflowId: PropTypes.string.isRequired,
};

export default withDeletionDependencyCheck(EditBrandingContainer);
