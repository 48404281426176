import PropTypes from 'prop-types';
import ruleIcon from '../../assests/icons/rule.svg';
import DebouncedTextEditor from '../Branding/DebouncedTextEditor';
import grabIcon from '../../assests/icons/drag.svg';

const threeDotIcon = '⋮';

function RuleGroupHeader({
  actionId,
  action,
  selectedAction,
  isRenaming,
  setIsRenaming,
  handleActionClick,
  handleRuleNameChange,
  ruleMenuOpen,
  setRuleMenuOpen,
  ruleMenuRef,
  handleRemoveAction,
}) {
  return (
    <div
      className={`rule-group__header ${selectedAction?.id === actionId && selectedAction?.operationId === null ? 'rule-group__header--active' : ''}`}
      onClick={() => handleActionClick({ id: actionId, ...action, operationId: null })}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          handleActionClick({ id: actionId, ...action, operationId: null });
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="rule-group__number-container">
        <div className="rule-group__number">{action.order}</div>
        <img src={grabIcon} alt="grab" className="grab-icon" />
      </div>
      <div className="rule-group__title">
        <img src={ruleIcon} alt="rule" />
        {isRenaming === `rule-${actionId}` ? (
          <div
            className="action-name-editor"
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            role="textbox"
            tabIndex={0}
          >
            <DebouncedTextEditor
              text={action.name || ''}
              onTextChange={(newText) => {
                if (newText.trim() !== action.name) {
                  handleRuleNameChange(actionId, newText);
                  setIsRenaming(null);
                }
              }}
            />
          </div>
        ) : (
          <span>{action.name}</span>
        )}
      </div>
      <div className="rule-menu" ref={ruleMenuRef}>
        <button
          type="button"
          className={`three-dot-button ${ruleMenuOpen === `rule-${actionId}` ? 'visible' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            setRuleMenuOpen(ruleMenuOpen === `rule-${actionId}` ? null : `rule-${actionId}`);
          }}
        >
          <span className="icon-text">{threeDotIcon}</span>
        </button>

        {ruleMenuOpen === `rule-${actionId}` && (
        <div className="action-dropdown-menu">
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setIsRenaming(`rule-${actionId}`);
              setRuleMenuOpen(null);
            }}
          >
            Rename
          </button>
          <button
            type="button"
            className="remove-button"
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveAction(actionId);
              setRuleMenuOpen(null);
            }}
          >
            Remove
          </button>
        </div>
        )}
      </div>
    </div>
  );
}

RuleGroupHeader.propTypes = {
  actionId: PropTypes.string.isRequired,
  action: PropTypes.object.isRequired,
  selectedAction: PropTypes.object,
  isRenaming: PropTypes.string,
  setIsRenaming: PropTypes.func.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  handleRuleNameChange: PropTypes.func.isRequired,
  ruleMenuOpen: PropTypes.string,
  setRuleMenuOpen: PropTypes.func.isRequired,
  ruleMenuRef: PropTypes.object.isRequired,
  handleRemoveAction: PropTypes.func.isRequired,
};

RuleGroupHeader.defaultProps = {
  selectedAction: null,
  isRenaming: null,
  ruleMenuOpen: null,
};

export default RuleGroupHeader;
