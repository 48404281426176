const changeActionMenuOptions = [
  {
    label: 'Actions',
    items: [
      {
        label: 'Navigate to',
        value: 'nextStep',
        displayLabel: 'Navigate to',
      },
      {
        label: 'Reload component',
        value: 'reloadV2',
        displayLabel: 'Reload component',
      },
    ],
    disabled: false,
  },
];

export default changeActionMenuOptions;
