import Joi from 'joi-browser';

const eventsV2WithIdsSchema = Joi.object().pattern(
  Joi.string(), // Allow any string as a key for the top-level object (event IDs)
  Joi.object().pattern(
    Joi.string(), // Allow any string as a key for the event type (e.g., "click", "change")
    Joi.object({
      actions: Joi.object().pattern(
        Joi.string(), // Allow any string as a key for the actions object
        Joi.object({
          operations: Joi.object().pattern(
            Joi.string(), // Allow any string as a key for the operations object
            Joi.object({
              reloadV2: Joi.object().default({}).optional(), // Allow empty object
              nextStep: Joi.string().allow('').optional(), // Allow empty string
            }).unknown(true), // Allow other keys within the operations object
          ).required(),
          name: Joi.string().optional().allow(''),
          order: Joi.number().optional(),
          rule: Joi.string().optional().allow(''),
        }),
      ).optional(),
    }),
  ),
);

export const refreshMapSchema = Joi.object().pattern(
  Joi.string(),
  Joi.object().allow(null).optional(),
).required();

export default eventsV2WithIdsSchema;
