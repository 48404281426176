import {
  cloneDeep, unset,
} from 'lodash';
import {
  fetchCurrentValueFromWorkflow,
  fetchCurrentValueFromWorkflowConfig,
  getSelectedModule,
  setModulePropertyInWorkflow,
  setSuperModulePatchProperty,
} from '../../components/ViewWorkflow/InputsToModule/utils/updateWorkflow';
import {
  addNodeFromHtmlString,
  copyComponentConfigs,
  copyNodeFromHtmlString,
  deleteNodeFromHtmlString,
  dragNodeFromHtmlString,
  getComponentConfigsWithRefreshedIds,
  getFormHtmlStringForV2,
  getNewComponentNameInFormV2,
  updateTagNameOfNodeInHtmlString,
} from '../../containers/FormModule/helper';
import DefaultError from '../../error/defaultError';
import { errorCodes } from '../../utils/error';
import generateUniqueID from '../../utils/generateUniqueId';

export const getActionName = (action) => {
  if (action.rule) {
    return 'Rule';
  }
  if (action.operations.reloadV2) {
    return 'Reload component';
  }
  return 'Navigate to';
};

export const addFormComponentInV2 = (workflow, moduleId, componentId) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const componentConfig = fetchCurrentValueFromWorkflow(module, 'componentConfigs');
    const newNodeType = 'span';
    const nodePropertiesOfNewNode = {
      textContent: 'text-goes-here',
    };
    const newNodeData = {
      nodeType: newNodeType,
      nodeProperties: nodePropertiesOfNewNode,
    };
    const { html: updatedHtml, newNodeId } = addNodeFromHtmlString(
      componentId,
      htmlContent,
      null,
      newNodeData,
    );
    const componentName = getNewComponentNameInFormV2(newNodeType, componentConfig);
    const clonedComponentConfigs = cloneDeep(componentConfig);
    clonedComponentConfigs[newNodeId] = {
      name: componentName,
      attributes: {},
      properties: {
        ...nodePropertiesOfNewNode,
      },
    };
    let editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    editedWorkflow = setModulePropertyInWorkflow(
      editedWorkflow,
      moduleId,
      'componentConfigs',
      clonedComponentConfigs,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return {
      workflow,
      success: false,
      errorData: new DefaultError({
        code: errorCodes.addFormV2ComponentFailed,
        message: 'Add form component failed',
        originalError: err,
        debugInfo: {
          moduleId,
          componentId,
        },
      }),
    };
  }
};

export const deleteFormComponentInV2 = (workflow, moduleId, componentId) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const { html: updatedHtml, deletedNodeIds } = deleteNodeFromHtmlString(
      componentId,
      htmlContent,
    );
    const componentConfig = fetchCurrentValueFromWorkflow(module, 'componentConfigs');
    const clonedComponentConfigs = cloneDeep(componentConfig);
    deletedNodeIds.forEach((id) => {
      unset(clonedComponentConfigs, id);
    });
    let editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'componentConfigs',
      clonedComponentConfigs,
      {},
    );
    editedWorkflow = setModulePropertyInWorkflow(
      editedWorkflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return {
      workflow,
      success: false,
      errorData: new DefaultError({
        code: errorCodes.deleteFormV2ComponentFailed,
        message: 'Failed to delete form component',
        originalError: err,
        debugInfo: {
          moduleId,
          componentId,
        },
      }),
    };
  }
};

export const addFormComponentViaClipboardInFormV2 = (
  workflow,
  moduleId,
  htmlString,
  componentConfigsFromContextCopy,
) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const { html: updatedHtml, map } = addNodeFromHtmlString(null, htmlContent, htmlString);
    const componentConfig = fetchCurrentValueFromWorkflow(module, 'componentConfigs');
    const componentConfigForAddedNodes = getComponentConfigsWithRefreshedIds(
      componentConfigsFromContextCopy,
      map,
    );

    const updatedComponentConfigs = {
      ...cloneDeep(componentConfig || {}),
      ...(componentConfigForAddedNodes || {}),
    };

    let editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );

    editedWorkflow = setModulePropertyInWorkflow(
      editedWorkflow,
      moduleId,
      'componentConfigs',
      updatedComponentConfigs,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return {
      workflow,
      success: false,
      errorData: new DefaultError({
        code: errorCodes.addFormV2ComponentViaClipboardFailed,
        message: 'Failed to add form component via clipboard',
        originalError: err,
        debugInfo: {
          moduleId,
          htmlString,
        },
      }),
    };
  }
};

export const cloneFormComponentInV2 = (workflow, moduleId, componentId) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const { html: updatedHtml, map } = copyNodeFromHtmlString(componentId, htmlContent);

    const componentConfig = fetchCurrentValueFromWorkflow(module, 'componentConfigs');
    const updatedComponentConfig = copyComponentConfigs(componentConfig, map);

    let editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'componentConfigs',
      updatedComponentConfig,
      {},
    );
    editedWorkflow = setModulePropertyInWorkflow(
      editedWorkflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return {
      workflow,
      success: false,
      errorData: new DefaultError({
        code: errorCodes.cloneFormV2ComponentFailed,
        message: 'Failed to clone form component',
        originalError: err,
        debugInfo: {
          moduleId,
          componentId,
        },
      }),
    };
  }
};

export const dragComponentInFormV2 = (workflow, moduleId, pickComponentId, dropComponentId) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const updatedHtml = dragNodeFromHtmlString(pickComponentId, dropComponentId, htmlContent);
    const editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return {
      workflow,
      success: false,
      errorData: new DefaultError({
        code: errorCodes.dragFormV2ComponentFailed,
        message: 'Failed to drag form component',
        originalError: err,
        debugInfo: {
          moduleId,
          pickComponentId,
          dropComponentId,
        },
      }),
    };
  }
};

export const updateTagNameOfComponentInFormV2 = (
  workflow,
  moduleId,
  componentId,
  tagName,
  libraryUrl,
  styleSheetsUrl,
  defaultAttributes,
  defaultProperties,
  defaultStyles,
  libraryId,
  formComponentId,
  libraryName,
  componentLabel,
) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const updatedHtml = updateTagNameOfNodeInHtmlString(tagName, componentId, htmlContent);
    const moduleComponentConfig = fetchCurrentValueFromWorkflow(module, 'componentConfigs');
    const updatedComponentName = getNewComponentNameInFormV2(
      `${libraryName} | ${componentLabel}`,
      moduleComponentConfig,
    );
    let editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    const existingLibraries = fetchCurrentValueFromWorkflowConfig(
      editedWorkflow,
      moduleId,
      'scripts',
    );
    const existingStyleSheets = fetchCurrentValueFromWorkflowConfig(
      editedWorkflow,
      moduleId,
      'stylesheets',
    );
    const existingComponentConfig = fetchCurrentValueFromWorkflowConfig(
      editedWorkflow,
      moduleId,
      `componentConfigs['${componentId}']`,
    );
    const {
      attributes: existingAttributes = {},
      properties: existingProperties = {},
      styles: existingStyles = {},
      ...rest
    } = existingComponentConfig || {};

    if (libraryId && formComponentId) {
      const attributes = {};
      Object.entries(defaultAttributes).forEach(([key, value]) => {
        attributes[key] =
          typeof existingAttributes[key] !== 'undefined' ? existingAttributes[key] : value;
      });

      const properties = {};
      Object.entries(defaultProperties).forEach(([key, value]) => {
        properties[key] =
          typeof existingProperties[key] !== 'undefined' ? existingProperties[key] : value;
      });

      const styles = {};
      Object.entries(defaultStyles).forEach(([key, value]) => {
        styles[key] = typeof existingStyles[key] !== 'undefined' ? existingStyles[key] : value;
      });

      const updatedComponentConfig = {
        ...rest,
        componentRef: {
          libraryId,
          formComponentId,
        },
        attributes,
        properties,
        styles,
        name: updatedComponentName,
      };

      editedWorkflow = setModulePropertyInWorkflow(
        editedWorkflow,
        moduleId,
        `componentConfigs['${componentId}']`,
        updatedComponentConfig,
      );
    }

    // TODO: This should be a post operation
    // library should be added if components of the same are used
    if (libraryUrl && !existingLibraries.includes(libraryUrl)) {
      editedWorkflow = setModulePropertyInWorkflow(editedWorkflow, moduleId, 'scripts', [
        ...existingLibraries,
        libraryUrl,
      ]);
    }

    if (styleSheetsUrl && !existingStyleSheets.includes(styleSheetsUrl)) {
      editedWorkflow = setModulePropertyInWorkflow(editedWorkflow, moduleId, 'stylesheets', [
        ...existingStyleSheets,
        styleSheetsUrl,
      ]);
    }
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return {
      workflow,
      success: false,
      errorData: new DefaultError({
        code: errorCodes.updateTagNameOfFormV2ComponentFailed,
        message: 'Failed to update tag name of form component',
        originalError: err,
        debugInfo: {
          moduleId,
          componentId,
        },
      }),
    };
  }
};

export const updateFormV2PropertyInSuperModule = (
  workflow,
  superModuleId,
  mappingId,
  componentId,
  key,
  value,
) => {
  const jsonPathQuery = `componentConfigs['${componentId}'].${key}`;
  const editedWorkflow = setSuperModulePatchProperty(
    workflow,
    superModuleId,
    `${mappingId}[+]${jsonPathQuery}`,
    value,
  );
  return { workflow: editedWorkflow, success: true };
};

export const processEventsV2 = (eventsV2Copy, refreshConfig = null) => {
  const processedEvents = {};
  Object.values(eventsV2Copy).forEach((eventData) => {
    Object.entries(eventData).forEach(([eventType, eventConfig]) => {
      if (eventConfig.actions) {
        // Convert actions object to array
        const actionsArray = Object.values(eventConfig.actions).map((action) => {
          const { operations: rawOperations, rule } = action;
          const operations = {};

          // Remove operation IDs
          Object.values(rawOperations).forEach((operation) => {
            Object.entries(operation).forEach(([opType, opConfig]) => {
              if (opType === 'reloadV2' || opType === 'nextStep') {
                operations[opType] = opConfig;
              }
            });
          });
          // Build the action object with optional rule
          const processedAction = { operations };
          if (rule) processedAction.rule = rule;

          return processedAction;
        });

        processedEvents[eventType] = {
          ...eventConfig,
          actions: actionsArray,
        };

        // Add refresh configuration if available for this specific event type
        if (refreshConfig?.[eventType]) {
          processedEvents[eventType].refresh = refreshConfig[eventType];
        }
      }
    });
  });
  return processedEvents;
};

export const updateEventsV2InWorkflow = ({
  workflow, eventsV2WithIds, selectedModuleId, refreshMap, componentId,
}) => {
  try {
    let editedWorkflow;
    const workflowCopy = cloneDeep(workflow);
    const moduleIndex = workflowCopy.modules.findIndex((m) => m.id === selectedModuleId);
    const module = moduleIndex !== -1 ? workflowCopy.modules[moduleIndex] : null;
    const component = module?.properties?.componentConfigs?.[componentId];

    if (component) {
      const eventsV2Copy = cloneDeep(eventsV2WithIds);
      const refreshConfig = component?.componentRef?.libraryId
      && component?.componentRef?.formComponentId
        ? refreshMap?.[component.componentRef.libraryId]?.[component.componentRef.formComponentId]
        : null;

      const processedEvents = processEventsV2(eventsV2Copy, refreshConfig);
      editedWorkflow = setModulePropertyInWorkflow(
        workflowCopy,
        selectedModuleId,
        `componentConfigs.${componentId}.eventsV2`,
        processedEvents,
      );
    } else {
      editedWorkflow = workflowCopy;
    }

    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return {
      workflow,
      success: false,
      errorData: new DefaultError({
        code: errorCodes.errorSettingEventsV2,
        message: 'Failed to set events V2',
        originalError: err,
        debugInfo: {
          workflow,
          eventsV2WithIds,
          selectedModuleId,
        },
      }),
    };
  }
};

export const preProcessEvents = (eventsV2) => {
  const processedEvents = {};

  Object.entries(eventsV2).forEach(([eventType, eventConfig]) => {
    const eventId = generateUniqueID();
    const { refresh, ...eventConfigWithoutRefresh } = eventConfig;
    let count = 0;
    processedEvents[eventId] = {
      [eventType]: {
        ...eventConfigWithoutRefresh,
        // Only include actions if they exist and are not empty
        ...(eventConfig.actions?.length > 0 && {
          actions: eventConfig.actions.reduce((acc, action) => {
            const actionId = generateUniqueID();
            acc[actionId] = {
              ...action,
              name: (() => {
                if (action.rule) return 'Rule';
                if (action.operations?.reloadV2) return 'Reload component';
                return 'Navigate to';
              })(),
              order: count += 1,
              operations: action.operations ?
                Object.entries(action.operations).reduce((opAcc, [opType, opConfig]) => {
                  const operationId = generateUniqueID();
                  return {
                    ...opAcc,
                    [operationId]: {
                      name: opType === 'reloadV2' ? 'reload component' : 'Navigate to',
                      [opType]: opConfig,
                    },
                  };
                }, {}) : {},
            };
            return acc;
          }, {}),
        }),
      },
    };
  });

  return processedEvents;
};
