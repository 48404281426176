import PropTypes from 'prop-types';
import DropDown from '../ViewWorkflow/DropDown';

function ConfigureEventsV2ModalHeader({
  eventDropdownOptions,
  selectedEventData,
  handleEventChange,
  onClose,
  eventsV2,
}) {
  return (
    <div className="configure-event__header">
      <div className="configure-event__event-dropdown">
        <DropDown
          items={{ items: eventDropdownOptions }}
          onChange={(item) => handleEventChange(item.value)}
          noSelectLabel="Select an event"
          defaultValue={selectedEventData?.eventType || ''}
          allowCustomInput
          textBoxPlaceholder="value"
          maxLength={30}
        />
      </div>
      <button
        type="button"
        className="configure-event__close-button"
        onClick={() => onClose(eventsV2)}
      >
        ×
      </button>
    </div>
  );
}

ConfigureEventsV2ModalHeader.propTypes = {
  eventDropdownOptions: PropTypes.array.isRequired,
  handleEventChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  eventsV2: PropTypes.object.isRequired,
  selectedEventData: PropTypes.object.isRequired,
};

export default ConfigureEventsV2ModalHeader;
