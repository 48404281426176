import PropTypes from 'prop-types';
import DropDown from '../ViewWorkflow/DropDown';

function ConfigureEventsV2ModalEmptyState({ addMenuOptions, handleAddMenuSelection }) {
  return (
    <div className="configure-event__empty-state">
      <div className="empty-state__icon" />
      <div className="empty-state__text-container">
        <div className="empty-state__text">
          Add actions or rule-based logic to define component
          <br />
          behaviour when this event is triggered.
        </div>
        <div className="empty-state__dropdown">
          <DropDown
            items={{ items: addMenuOptions }}
            onChange={handleAddMenuSelection}
            noSelectLabel="Add Action or Rule"
            defaultValue=""
          />
        </div>
      </div>
    </div>
  );
}

ConfigureEventsV2ModalEmptyState.propTypes = {
  addMenuOptions: PropTypes.array.isRequired,
  handleAddMenuSelection: PropTypes.func.isRequired,
};

export default ConfigureEventsV2ModalEmptyState;
