import PropTypes from 'prop-types';
import addIcon2 from '../../assests/icons/addInsideContainer.svg';
import navigationIcon from '../../assests/icons/navigation.svg';
import reloadIcon from '../../assests/icons/reload.svg';

function RuleGroupAddAction({
  actionId,
  operationMenuOpen,
  setOperationMenuOpen,
  handleAddOperation,
  operationMenuRef,
}) {
  return (
    <div className="rule-group__add-action-container" ref={operationMenuRef}>
      <button
        type="button"
        className="rule-group__add-action"
        onClick={() => setOperationMenuOpen(true)}
      >
        <img src={addIcon2} alt="plus" className="plus-icon" />
        <div className="rule-group__add-action-text">
          Insert action
        </div>
      </button>

      {operationMenuOpen && (
        <div className="operation-menu">
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              handleAddOperation(actionId, 'nextStep');
              setOperationMenuOpen(false);
            }}
          >
            <img src={navigationIcon} alt="navigate" />
            <div className="operation-menu__text">
              Navigate to
            </div>
          </button>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              handleAddOperation(actionId, 'reloadV2');
              setOperationMenuOpen(false);
            }}
          >
            <img src={reloadIcon} alt="reload" />
            <div className="operation-menu__text">
              Reload component
            </div>
          </button>
        </div>
      )}
    </div>
  );
}

RuleGroupAddAction.propTypes = {
  actionId: PropTypes.string.isRequired,
  operationMenuOpen: PropTypes.bool.isRequired,
  setOperationMenuOpen: PropTypes.func.isRequired,
  handleAddOperation: PropTypes.func.isRequired,
  operationMenuRef: PropTypes.object.isRequired,
};

export default RuleGroupAddAction;
