const addMenuOptions = [
  {
    label: 'Actions',
    items: [
      {
        label: 'Navigate to',
        value: 'nextStep',
        displayLabel: 'Navigate to',
      },
      {
        label: 'Reload component',
        value: 'reloadV2',
        displayLabel: 'Reload component',
      },
    ],
    disabled: false,
  },
  {
    label: 'Rule',
    items: [
      {
        label: 'Add rule',
        value: 'rule',
        displayLabel: 'Add rule',
      },
    ],
    disabled: false,
  },
];

export default addMenuOptions;
