import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import RuleGroupHeader from './RuleGroupHeader';
import RuleGroupAction from './RuleGroupAction';
import RuleGroupAddAction from './RuleGroupAddAction';

function RuleGroup({
  actionId,
  action,
  isDragging,
  dragOverId,
  selectedAction,
  handleDragStart,
  handleDrop,
  setDragOverId,
  setIsDragging,
  handleRuleNameChange,
  handleRemoveAction,
  handleActionClick,
  handleOperationNameChange,
  handleRemoveOperation,
  handleAddOperation,
}) {
  const [actionMenuOpen, setActionMenuOpen] = useState(null);
  const [operationMenuOpen, setOperationMenuOpen] = useState(null);
  const [ruleMenuOpen, setRuleMenuOpen] = useState(null);
  const [isRenaming, setIsRenaming] = useState(null);

  const actionMenuRef = useRef(null);
  const operationMenuRef = useRef(null);
  const ruleMenuRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        operationMenuRef.current &&
        !operationMenuRef.current.contains(event.target)
      ) {
        setOperationMenuOpen(null);
      }
      if (
        ruleMenuRef.current &&
        !ruleMenuRef.current.contains(event.target)
      ) {
        setRuleMenuOpen(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [actionMenuOpen, operationMenuOpen, ruleMenuOpen]);

  const renderOperationText = (operation) => {
    const firstOperation = Object.keys(operation)[0];
    return firstOperation;
  };

  return (
    <div
      className={`rule-group ${selectedAction?.id === actionId && selectedAction?.operationId === null ? 'rule-group__active' : ''} ${
        isDragging === actionId ? 'dragging' : ''
      } ${dragOverId === actionId ? 'drag-over' : ''}`}
      draggable
      onDragStart={() => handleDragStart(actionId)}
      onDragEnd={() => {
        setIsDragging(null);
        setDragOverId(null);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        if (actionId !== isDragging) {
          setDragOverId(actionId);
        }
      }}
      onDragLeave={() => {
        setDragOverId(null);
      }}
      onDrop={(e) => {
        e.preventDefault();
        handleDrop(actionId);
        setDragOverId(null);
      }}
    >
      <RuleGroupHeader
        actionId={actionId}
        action={action}
        selectedAction={selectedAction}
        isRenaming={isRenaming}
        setIsRenaming={setIsRenaming}
        handleActionClick={handleActionClick}
        handleRuleNameChange={handleRuleNameChange}
        ruleMenuOpen={ruleMenuOpen}
        setRuleMenuOpen={setRuleMenuOpen}
        ruleMenuRef={ruleMenuRef}
        handleRemoveAction={handleRemoveAction}
      />

      <div className="rule-group__content">
        {Object.entries(action.operations).map(([operationId, operation]) => (
          <RuleGroupAction
            key={`${actionId}-${operationId}`}
            action={action}
            actionId={actionId}
            operationId={operationId}
            operation={operation}
            selectedAction={selectedAction}
            isRenaming={isRenaming}
            setIsRenaming={setIsRenaming}
            handleActionClick={handleActionClick}
            handleOperationNameChange={handleOperationNameChange}
            handleRemoveOperation={handleRemoveOperation}
            actionMenuOpen={actionMenuOpen}
            setActionMenuOpen={setActionMenuOpen}
            actionMenuRef={actionMenuRef}
            renderOperationText={renderOperationText}
          />
        ))}
      </div>

      <RuleGroupAddAction
        actionId={actionId}
        operationMenuOpen={operationMenuOpen}
        setOperationMenuOpen={setOperationMenuOpen}
        handleAddOperation={handleAddOperation}
        operationMenuRef={operationMenuRef}
      />
    </div>
  );
}

RuleGroup.propTypes = {
  actionId: PropTypes.string.isRequired,
  action: PropTypes.object.isRequired,
  isDragging: PropTypes.string,
  dragOverId: PropTypes.string,
  selectedAction: PropTypes.object,
  handleDragStart: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  setDragOverId: PropTypes.func.isRequired,
  setIsDragging: PropTypes.func.isRequired,
  handleRuleNameChange: PropTypes.func.isRequired,
  handleRemoveAction: PropTypes.func.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  handleOperationNameChange: PropTypes.func.isRequired,
  handleRemoveOperation: PropTypes.func.isRequired,
  handleAddOperation: PropTypes.func.isRequired,
};

RuleGroup.defaultProps = {
  isDragging: null,
  dragOverId: null,
  selectedAction: null,
};

export default RuleGroup;
