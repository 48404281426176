import { set } from 'lodash';

const createUpdatedUIConfig = (
  moduleId,
  uiConfig,
  originalToClonedComponentIdMap,
  uiConfigData,
) => {
  const updatedUIConfig = { ...uiConfig, [moduleId]: { ...uiConfig[moduleId] } };
  Object.entries(originalToClonedComponentIdMap).forEach(([originalId, clonedId]) => {
    if (uiConfigData[originalId]) {
      updatedUIConfig[moduleId][clonedId] = uiConfigData[originalId];
    }
  });

  return updatedUIConfig;
};

export const generateRefreshMap = (libraryReference) => {
  const refreshMap = {};

  Object.entries(libraryReference).forEach(([libraryId, library]) => {
    library.components.forEach((component) => {
      component.events?.forEach((eventObj) => {
        Object.entries(eventObj).forEach(([eventId, eventConfig]) => {
          if (eventConfig?.refresh) {
            set(refreshMap, [libraryId, component.id, eventId], eventConfig.refresh);
          }
        });
      });
    });
  });
  return refreshMap;
};

export default createUpdatedUIConfig;
