import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { set } from 'lodash';
import PropTypes from 'prop-types';
import ErrorModal from './ErrorModal';
import CreateWorkflowButton from './CreateWorkflowButton';
import useShowErrorAlert from '../../hooks/custom/useCustomAlert';
import useApiHooks from '../../hooks/api/useApiHooks';
import createWorkflowId from './helper';
import FileUpload from './JsonFileUpload';
import { generateWorkflowConfigFromModuleConfigs, generateWorkflowUiConfigFromModuleConfigs, generateWorkflowTextConfigFromModuleConfigs } from '../../compilerDecompiler/workflowModuleInverseTransformer';
import { selectVersionedModules } from '../../reducers/workflow';
import useFetchRequiredModules from '../../utils/fetchRequiredModules';
import { validateModuleConfig, validateModuleUiConfig } from '../../moduleBuilderOperations/validations';
import closeIcon from '../../assests/icons/closeIcon.svg';
import validateAndProcessWorkflow from './validateAndProcessWorkflow';
import { getRequiredModuleVersions } from '../../utils/helper';
import changeEndState from '../../workflowOperations/operations/changeEndStates';
import ErrorHandlerContext from '../../context/ErrorHandlerContext';
import HVError, { errorCodes } from '../../utils/error';
import DefaultError from '../../error/defaultError';

function ImportWorkflow({ currentAppId, moduleBuilderMode }) {
  const versionedModules = useSelector(selectVersionedModules);
  const [showImportModal, setShowImportModal] = useState(false);
  const [workflowData, setWorkflowData] = useState({});
  const [enableUpload, setEnableUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [isWorkflowValidationSuccessful, setIsWorkflowValidationSuccessful] = useState(false);
  const { fetchRequiredModules } = useFetchRequiredModules(versionedModules);
  const navigate = useNavigate();
  const showErrorAlert = useShowErrorAlert();
  const handleError = useContext(ErrorHandlerContext);

  const {
    publishTextConfig, publishUiConfig, publishWorkflow,
  } = useApiHooks();

  const toggleImportModal = () => {
    setShowImportModal((status) => !status);
    setIsLoading(false);
    setWorkflowData({});
  };

  const setWorkflowProperties = (workflow) => {
    set(workflow, 'properties.builtOnBuilder', true);
    if (!workflow?.properties?.sdkVersions
      || !Object.keys(workflow?.properties?.sdkVersions).length) {
      set(workflow, 'properties.sdkVersions', {
        mobile: {
          maximum: '10.0.0',
          minimum: '0.3.0',
        },
        web: {
          maximum: '10.0.0',
          minimum: '5.0.0',
        },
      });
    }
    return workflow;
  };
  const handleModalClose = () => {
    setShowErrorModal(false);
    toggleImportModal();
  };
  const handleUploadButton = async (data) => {
    const {
      textConfig,
      uiConfig,
      workflowConfig,
    } = data;
    if (currentAppId && workflowConfig) {
      setIsLoading(true);
      const workflowId = createWorkflowId();
      const workflow = setWorkflowProperties(workflowConfig);
      let updatedErrorData;
      let updatedWorkflow;
      let isValidationSuccessful = true;

      try {
        const requiredModuleVersions = getRequiredModuleVersions(workflow);
        const requiredModules = await fetchRequiredModules(requiredModuleVersions, true);
        ({
          workflow: updatedWorkflow,
          errorData: updatedErrorData,
          isSuccess: isValidationSuccessful,
        } = validateAndProcessWorkflow(workflow, requiredModules, moduleBuilderMode));
        if (isValidationSuccessful) {
          ({ workflow: updatedWorkflow } = changeEndState(updatedWorkflow, 'approve', 'auto_approved'));
          ({ workflow: updatedWorkflow } = changeEndState(updatedWorkflow, 'decline', 'auto_declined'));
          ({ workflow: updatedWorkflow } = changeEndState(updatedWorkflow, 'manualReview', 'needs_review'));
        }

        setIsWorkflowValidationSuccessful(isValidationSuccessful);
      } catch (error) {
        const processedError = new DefaultError({
          code: errorCodes.errorImportingWorkflow,
          message: 'Failed to import workflow!',
          originalError: error,
          debugInfo: {},
        });
        handleError(processedError);
        setIsLoading(false);
        return;
      }

      if (updatedErrorData?.length > 0) {
        // Set error data and show modal
        setErrorData(updatedErrorData);
        setWorkflowData((prevData) => ({
          ...prevData,
          workflowConfig: updatedWorkflow,
        }));
        setShowErrorModal(true);
        setIsLoading(false); // Stop loading since we need to show modal
      } else {
        const promises = [
          publishWorkflow(currentAppId, workflowId, updatedWorkflow),
        ];
        if (textConfig && Object.keys(textConfig).length > 0) {
          promises.push(publishTextConfig(workflowId, textConfig, 'en'));
        }

        if (uiConfig && Object.keys(uiConfig).length > 0) {
          promises.push(publishUiConfig(workflowId, uiConfig));
        }

        const responses = await Promise.all(promises);
        setIsLoading(false);
        const failedPromise = responses.find((response) => !response.isSuccessful);
        if (failedPromise && Object.keys(failedPromise)) {
          showErrorAlert({ error: failedPromise.error, message: failedPromise.message });
        } else if (updatedErrorData.length === 0) {
          navigate(`/view?id=${workflowId}`);
          toggleImportModal();
        }
      }
    }
    setEnableUpload(true);
  };

  const handleConfirm = () => {
    setShowErrorModal(false);
    handleUploadButton(workflowData);
  };

  const generateConfig = (moduleConfig, moduleUiConfig) => ({
    uiConfig: generateWorkflowUiConfigFromModuleConfigs(moduleConfig),
    workflow: generateWorkflowConfigFromModuleConfigs(moduleConfig, moduleUiConfig),
    textConfig: generateWorkflowTextConfigFromModuleConfigs(moduleConfig),
  });
  const handleClose = (configType) => {
    setWorkflowData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[configType] = null;
      return updatedData;
    });

    if (configType === 'workflowConfig' || configType === 'moduleConfig' || configType === 'moduleUiConfig') {
      setEnableUpload(false);
    }
  };

  const handleImportModule = async (data) => {
    try {
      const { moduleConfig, moduleUiConfig } = data;
      validateModuleConfig(moduleConfig);
      validateModuleUiConfig(moduleUiConfig);

      const {
        uiConfig,
        workflow,
        textConfig,
      } = generateConfig(moduleConfig, moduleUiConfig);
      const configData = {
        ...workflowData,
        workflowConfig: workflow,
        uiConfig,
        textConfig,
        moduleConfig,
        moduleUiConfig,
      };
      setWorkflowData((prev) => ({
        ...prev,
        ...configData,
      }));

      await handleUploadButton(configData);
    } catch (error) {
      if (error instanceof HVError) {
        handleError(error);
      } else {
        const processedError = new DefaultError({
          code: errorCodes.errorImportingModule,
          message: 'Failed to import module!',
          originalError: error,
          debugInfo: {},
        });
        handleError(processedError);
      }
      setIsLoading(false);
    }
  };

  const handleDataChange = (data, key) => {
    setWorkflowData((prevData) => ({
      ...prevData,
      [key]: data,
    }));
  };

  useEffect(() => {
    if (moduleBuilderMode) {
      setEnableUpload(currentAppId && workflowData?.moduleConfig && workflowData.moduleUiConfig);
    } else {
      setEnableUpload(currentAppId && workflowData?.workflowConfig);
    }
  }, [workflowData, currentAppId, moduleBuilderMode]);

  return (
    <>
      <div className="import_workflow__item">
        <button type="button" onClick={toggleImportModal} className="import_workflow__item_button">
          Import Workflow
        </button>
      </div>
      { showImportModal
        && (
          <div className="import_workflow__drawer_background">
            <div className="import_workflow__drawer">
              { moduleBuilderMode ?
                (
                  <>
                    <div className="import_workflow__header_container">
                      <div className="import_workflow__header">
                        Import your modules
                      </div>
                      <button type="button" className="import_workflow__close" onClick={toggleImportModal}>
                        <img src={closeIcon} alt="close" />
                      </button>

                    </div>
                    <div className="import_workflow__content_container">
                      <FileUpload
                        title="Module Config"
                        onChange={(jsonData) => { handleDataChange(jsonData, 'moduleConfig'); }}
                        onClose={() => handleClose('moduleConfig')}
                      />
                      <FileUpload
                        title="Module UI Config"
                        onChange={(jsonData) => { handleDataChange(jsonData, 'moduleUiConfig'); }}
                        onClose={() => handleClose('moduleUiConfig')}
                      />
                    </div>
                  </>
                ) :
                (
                  <>
                    <div className="import_workflow__header_container">
                      <div className="import_workflow__header">
                        Import your workflow
                      </div>
                      <button type="button" className="import_workflow__close" onClick={toggleImportModal}>
                        <img src={closeIcon} alt="close" />
                      </button>

                    </div>
                    <div className="import_workflow__content_container">
                      <FileUpload
                        title="Workflow"
                        onChange={(jsonData) => { handleDataChange(jsonData, 'workflowConfig'); }}
                        onClose={() => handleClose('workflowConfig')}
                      />
                      <FileUpload
                        title="Text Config (en) (Optional)"
                        onChange={(jsonData) => { handleDataChange(jsonData, 'textConfig'); }}
                        onClose={() => handleClose('textConfig')}
                      />
                      <FileUpload
                        title="UI Config (Optional)"
                        onChange={(jsonData) => { handleDataChange(jsonData, 'uiConfig'); }}
                        onClose={() => handleClose('uiConfig')}
                      />
                    </div>
                  </>
                )}
              <div className="import_workflow__footer_container">
                { enableUpload
                  ? (
                    <CreateWorkflowButton
                      onButtonClick={moduleBuilderMode ?
                        () => handleImportModule(workflowData) :
                        () => handleUploadButton(workflowData)}
                      normalButtonText="Upload"
                      loadingButtonText=""
                      isLoading={isLoading}
                      buttonClassName="import_workflow__button_submit"
                      progressStyles={{ size: '1.25rem', sx: { color: 'white' } }}
                    />
                  )
                  : <button className="import_workflow__button_submit_disabled" type="submit">Proceed</button>}
              </div>
            </div>
          </div>
        )}
      {showErrorModal && (
        <ErrorModal
          isOpen={showErrorModal}
          onClose={handleModalClose}
          errorData={errorData}
          onConfirm={handleConfirm}
          isWorkflowValidationSuccessful={isWorkflowValidationSuccessful}
          headerText="Your Workflow Needed Some Tweaks"
        />
      )}
    </>
  );
}

export default ImportWorkflow;

ImportWorkflow.propTypes = {
  currentAppId: PropTypes.string.isRequired,
  moduleBuilderMode: PropTypes.bool.isRequired,
};
