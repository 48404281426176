import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import DebouncedTextEditor from '../Branding/DebouncedTextEditor';
import navigationIcon from '../../assests/icons/navigation.svg';
import reloadIcon from '../../assests/icons/reload.svg';
import grabIcon from '../../assests/icons/drag.svg';

const threeDotIcon = '⋮';

function StandaloneAction({
  actionId,
  action,
  isRenaming,
  selectedAction,
  handleDragStart,
  handleDrop,
  dragOverId,
  setDragOverId,
  handleActionClick,
  handleActionNameChange,
  setIsRenaming,
  handleRemoveAction,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return Object.entries(action.operations).map(([operationId, operation]) => {
    const operationType = Object.keys(operation).find((key) => key !== 'name');
    const isEditing = isRenaming === actionId;

    return (
      <>
        {dragOverId === actionId ?
          <div className="rule-group__action--standalone--drag-over" />
          : null}
        <div
          key={actionId}
          className={`rule-group__action--standalone ${
            selectedAction?.operationId === operationId ? 'rule-group__action--standalone--selected' : ''
          }`}
          onClick={() => {
            if (!isEditing) {
              handleActionClick({ id: actionId, operationId });
            }
          }}
          onKeyDown={(e) => {
            if (!isEditing && (e.key === 'Enter' || e.key === ' ')) {
              e.preventDefault();
              handleActionClick({ id: actionId, operationId });
            }
          }}
          role="button"
          tabIndex={0}
          draggable={!isEditing}
          onDragStart={() => handleDragStart(actionId)}
          onDragOver={(e) => {
            e.preventDefault();
            if (actionId !== dragOverId) {
              setDragOverId(actionId);
            }
          }}
          onDrop={(e) => {
            e.preventDefault();
            handleDrop(actionId);
            setDragOverId(null);
          }}
          onDragEnd={() => setDragOverId(null)}
        >
          <div className="action-content">
            <div className="action-number-container">
              <span className="action-number">{action.order}</span>
              <img src={grabIcon} alt="grab" className="grab-icon" />
            </div>
            <span className="action-icon">
              <img src={operationType === 'nextStep' ? navigationIcon : reloadIcon} alt="operation" />
            </span>
            {isEditing ? (
              <div
                className="action-name-editor"
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                role="textbox"
                tabIndex={0}
              >
                <DebouncedTextEditor
                  text={action.name || ''}
                  onTextChange={(newText) => {
                    handleActionNameChange(actionId, newText);
                    if (newText.trim() !== action.name) {
                      setIsRenaming(null);
                    }
                  }}
                />
              </div>
            ) : (
              <span className="action-name">{action.name}</span>
            )}
          </div>

          <div className="action-menu" ref={menuRef}>
            <button
              type="button"
              className={`three-dot-button ${isMenuOpen ? 'visible' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                setIsMenuOpen(!isMenuOpen);
              }}
            >
              <span className="icon-text">{threeDotIcon}</span>
            </button>

            {isMenuOpen && (
            <div className="action-dropdown-menu">
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsRenaming(actionId);
                  setIsMenuOpen(false);
                }}
              >
                Rename
              </button>
              <button
                type="button"
                className="remove-button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveAction(actionId);
                  setIsMenuOpen(false);
                }}
              >
                Remove
              </button>
            </div>
            )}
          </div>
        </div>
      </>
    );
  });
}

StandaloneAction.propTypes = {
  actionId: PropTypes.string.isRequired,
  action: PropTypes.object.isRequired,
  isRenaming: PropTypes.string,
  selectedAction: PropTypes.object,
  handleDragStart: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  setDragOverId: PropTypes.func.isRequired,
  dragOverId: PropTypes.string,
  handleActionClick: PropTypes.func.isRequired,
  handleActionNameChange: PropTypes.func.isRequired,
  setIsRenaming: PropTypes.func.isRequired,
  handleRemoveAction: PropTypes.func.isRequired,
};

export default StandaloneAction;
