/* eslint-disable no-param-reassign */
import {
  useEffect, useState, useMemo,
  useContext,
} from 'react';
import { PermissionWrapper } from 'storybook-ui-components';
import {
  useNodesState,
  useEdgesState,
  MarkerType,
  ReactFlowProvider,
} from 'reactflow';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { cloneDeep } from 'lodash';
import './Container.scss';
import convertToNodesEdges, { updateNextStepForModule, addUIPropertiesToNodes } from '../components/utils';
import { DRAWER_TYPES } from '../components/constants';
import GenericModuleDrawer from '../components/ViewWorkflow/GenericModuleDrawer';
import DefaultDrawer from '../components/ViewWorkflow/DefaultDrawer';
import ModuleNode from '../components/ModuleNode';
import ApproveNode from '../components/ApproveNode';
import ConditionNode from '../components/ConditionNode';
import DeclineNode from '../components/DeclineNode';
import OutputDrawer from '../components/ViewWorkflow/OutputDrawer';
import ConditionEdge from '../components/ConditionEdge.js';
import ModuleEdge from '../components/ModuleEdge';
import ELKLayout from '../components/ViewWorkflow/ELKLayout';
import useGetUserPermissions from '../hooks/permissions/usePermissions.js';
import getPermission from '../Permissions/mapping';
import NewBaseDrawer from '../components/ViewWorkflow/NewBaseDrawer';
import UpdateModal from '../components/ImportWorkflow/UpdateModal.js';
import renameEditIcon from '../assests/icons/updateIcon.svg';

import {
  updateOrderOfNodes,
  updateSelectedNode,
  selectSelectedWorkflow,
  selectIsWorkflowEdited,
  selectOrderOfNodes,
  selectSelectedNode,
  selectVersionedModules,
} from '../reducers/workflow';

import {
  selectModuleBuilderMode,
} from '../reducers/moduleBuilder.js';

import PublishWorkflow from '../components/ViewWorkflow/PublishWorkflow';
import StartNode from '../components/StartNode';
import ReviewNode from '../components/ReviewNode';
import AddNodeModal from '../components/AddNodeModal';
import EditNodeModal from '../components/EditNodeModal';
import GotoNode from '../components/GotoNode';
import StartDrawer from '../components/ViewWorkflow/StartDrawer';
import MoreWorkflowOptions from '../components/ViewWorkflow/MoreWorkflowOptions';
import {
  updateGotoInWorkflow,
} from './workflowOperations';
import { updateWorkflowInState } from '../workflowOperations/updateWorkflow';
import Modal from '../components/Common/Modal';
import SingleSelectDropdown from '../components/Common/SingleSelectDropdown';
import GotoDrawer from '../components/ViewWorkflow/GotoDrawer';
import { getConditionAndModuleItems } from '../utils/helper';
import withDeletionDependencyCheck from '../utils/withDeletionDependencyCheck.js';
import {
  selectCustomTextConfig,
  selectCustomUIConfig,
  selectDefaultTextConfig,
  selectDefaultUIConfig,
  selectSelectedLanguage,
  updateCustomTextConfig,
} from '../reducers/editBranding';
import { setDefaultUIConfigsForSuperModule, setDefaultTextConfigsForSuperModule } from './uiConfigOperations';
import { logEndStateUpdate } from '../logger/logHighLevelWorkflowUpdates';
import { selectDefaultFormSections } from '../reducers/dynamicForm.js';
import TryWorkflow from './ViewWorkflow/TryWorkflow.js';
import { workflowOperationsObj as operations } from '../workflowOperations';
import DismissNode from '../components/DismissNode';
import CancelledNode from '../components/CancelledNode';
import Search from '../components/ViewWorkflow/SearchModules.js';
import ModuleVersionUpdate from '../components/ViewWorkflow/ModuleVersionUpdate/ModuleVersionUpdate.js';
import ErrorHandlerContext from '../context/ErrorHandlerContext.js';

let localOrderOfNodes = [];
function ViewWorkflow() {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [workflowName, setWorkflowName] = useState('');
  const [workflowDescription, setWorkflowDescription] = useState('');
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [drawerType, setDrawerType] = useState(DRAWER_TYPES.none);
  const [addNodeBetween, setAddNodeBetween] = useState(null);
  const [editEndStateParent, setEditEndStateParent] = useState(null);
  const [showGotoModal, setShowGotoModal] = useState(null);
  const [showEditWorkflowDetailsModal, setShowEditWorkflowDetailsModal] = useState(false);
  const [newGotoTargetModuleId, setNewGotoTargetModuleId] = useState('');
  const [newNodeAdded, setNewNodeAdded] = useState(null);
  const [showDropDownFor, setShowDropDownFor] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isEscapePressed, setIsEscapePressed] = useState(false);
  const [modulesTobeUpdated, setModulesTobeUpdated] = useState([]);

  const defaultUiConfig = useSelector(selectDefaultUIConfig);
  const defaultTextConfigs = useSelector(selectDefaultTextConfig);
  const customUiConfig = useSelector(selectCustomUIConfig);
  const customTextConfig = useSelector(selectCustomTextConfig);
  const countryDocMapping = useSelector((state) => state.workflow.countryDocMapping);
  const versionedModules = useSelector(selectVersionedModules);
  const workflowConfig = useSelector(selectSelectedWorkflow);
  const orderOfNodes = useSelector(selectOrderOfNodes);
  const isWorkflowEdited = useSelector(selectIsWorkflowEdited);
  const defaultFormSections = useSelector(selectDefaultFormSections);
  const selectedNode = useSelector(selectSelectedNode);
  const selectedLanguage = useSelector(selectSelectedLanguage);
  const moduleBuilderMode = useSelector(selectModuleBuilderMode);
  const defaultTextConfig = useMemo(() => defaultTextConfigs[`default_${selectedLanguage}_text_config`], [selectedLanguage]);
  const navigate = useNavigate();

  const workflowId = new URLSearchParams(search).get('id');
  const handleError = useContext(ErrorHandlerContext);

  const resetDrawerType = () => {
    setDrawerType(DRAWER_TYPES.none);
    dispatch(updateSelectedNode({ selectedNode: {} }));
  };
  const showAddNodeModal = (parent, child, id) => {
    const edgeElement = document.getElementById(`${id}_addModule_edge`);
    edgeElement.removeAttribute('class');
    edgeElement.classList.add('edge-with-box');
    setAddNodeBetween({
      parent,
      child,
      edgeId: id,
    });
  };
  const closeAddNodeModal = () => {
    const edgeElement = document.getElementById(`${addNodeBetween.edgeId}_addModule_edge`);
    if (edgeElement) {
      edgeElement.removeAttribute('class');
      edgeElement.classList.add('edge-without-box');
    }
    setAddNodeBetween(null);
  };

  const deleteNode = ({
    id,
    parentId,
    workflow,
    customTextConfig: currCustomTextConfig,
    defaultTextConfig: currDefaultTextConfig,
  }) => {
    dispatch(updateSelectedNode({ selectedNode: {} }));
    resetDrawerType();
    const moduleToBeDeleted = workflow.modules.find((module) => module.id === id);
    try {
      updateWorkflowInState({}, true, {
        operation: operations.DELETE_MODULE,
        actionData: {
          targetNodeId: id,
          parentId,
        },
        sourceType: moduleToBeDeleted.type,
      });
    } catch (err) {
      handleError(err);
    }

    const deleteTextConfigOfModule = (moduleId, currTextConfig) => {
      const editedTextConfig = cloneDeep(currTextConfig);
      if (editedTextConfig[moduleId]) {
        delete editedTextConfig[moduleId];
      } else {
        Object.keys(editedTextConfig)
          .filter((key) => key.startsWith(moduleId))
          .forEach((key) => delete editedTextConfig[key]);
      }
      dispatch(updateCustomTextConfig({
        textConfig: editedTextConfig,
        language: selectedLanguage,
      }));
    };

    // Assumption: Only dynamic form module supports custom UI config edits for each component.
    // TODO: Should be moved to updateWorkflowInState ?
    const currTextConfig = currCustomTextConfig && Object.keys(currCustomTextConfig).length > 0
      ? currCustomTextConfig
      : currDefaultTextConfig;
    deleteTextConfigOfModule(id, currTextConfig);
  };

  const showDeleteNodeModal = (id, parentId, workflow) => {
    deleteNode({
      id,
      parentId,
      workflow,
      customTextConfig,
      defaultTextConfig,
    });
  };

  const updateGoto = (parentNodeId, parentBranch, updatedGotoModule, workflow) => {
    const editedWorkflow = updateGotoInWorkflow(
      parentNodeId,
      parentBranch,
      updatedGotoModule,
      workflow,
    );
    updateWorkflowInState(editedWorkflow);
  };

  const onCreateGoto = (gotoNodeDetails, newGotoModule, workflow) => {
    const { id: parentNodeId, parentBranch, parentPath } = gotoNodeDetails;
    updateGoto(parentNodeId, parentBranch || parentPath, newGotoModule, workflow);
    resetDrawerType();
    setNewGotoTargetModuleId('');
    setShowGotoModal(null);
  };

  const displayGotoModal = () => {
    const items = getConditionAndModuleItems(workflowConfig);
    return (
      <div className="goto-modal_content">
        <SingleSelectDropdown items={items} onElementSelect={(value) => setNewGotoTargetModuleId(value)} overrideDropdownStyles="single-select-dropdown_override" />
        <button
          type="button"
          className="goto-modal_content__button"
          disabled={!newGotoTargetModuleId}
          onClick={() => onCreateGoto(showGotoModal, newGotoTargetModuleId, workflowConfig)}
        >
          Submit
        </button>
      </div>
    );
  };

  const showEditNodeModal = (obj) => {
    setEditEndStateParent(obj);
  };

  const onEditEndState = (type, selectedWorkflow) => {
    const {
      id, parentBranch, parentPath,
    } = editEndStateParent;
    if (type === 'goto') {
      setShowGotoModal({ id, parentBranch, parentPath });
      setEditEndStateParent(null);
      return;
    }
    let currentEndState = null;
    const workflow = cloneDeep(selectedWorkflow);
    // set the nextstep of the parent
    workflow.modules.forEach((module, index) => {
      if (module.id === id) {
        module.next_node_type = {};
        const updatedModule = updateNextStepForModule(module, parentPath, type);
        workflow.modules[index] = updatedModule;
      }
    });
    const newConditions = {};
    const { conditions } = workflow;
    Object.entries(conditions).forEach(([conditionId, value]) => {
      newConditions[conditionId] = { ...value };
      if (conditionId === id) {
        currentEndState = newConditions[conditionId][parentBranch];
        newConditions[conditionId][parentBranch] = type;
        if (newConditions[conditionId].next_node_type
          && Object.keys(newConditions[conditionId].next_node_type).length) {
          newConditions[conditionId].next_node_type[parentBranch] = '';
        }
        // TODO create an operation for changing end states.
        if (newConditions[conditionId].ifFalseConfigs && (newConditions[conditionId].if_false !== 'decline' && newConditions[conditionId].if_false !== 'auto_declined')) {
          delete newConditions[conditionId].ifFalseConfigs;
        }

        if (newConditions[conditionId].ifTrueConfigs && (newConditions[conditionId].if_true !== 'decline' && newConditions[conditionId].if_true !== 'auto_declined')) {
          delete newConditions[conditionId].ifTrueConfigs;
        }
      }
    });
    workflow.conditions = newConditions;
    // setWorkflowConfig(workflow);

    updateWorkflowInState(workflow);
    resetDrawerType();
    setEditEndStateParent(null);
    logEndStateUpdate({
      id,
      parentBranch,
      oldEndState: currentEndState,
      newEndState: type,
    });
  };

  const handleEditWorkflow = () => {
    setShowEditWorkflowDetailsModal(true);
  };

  const addUIPropertiesEdges = (workflowNodes) => {
    const uiAddedEdges = workflowNodes.map((edge) => ({
      ...edge,
      type: edge.type || 'step',
      animated: false,
      markerEnd: {
        type: MarkerType.Arrow,
      },
      data: {
        showAddNodeModal,
        ...edge.data,
      },
    }));
    return uiAddedEdges;
  };

  const deleteCondition = (id, parentId) => {
    // set drawer type
    dispatch(updateSelectedNode({ selectedNode: {} }));
    setDrawerType(DRAWER_TYPES.none);
    try {
      updateWorkflowInState({}, true, {
        operation: operations.DELETE_CONDITION,
        actionData: {
          targetNodeId: id,
          parentId,
        },
        sourceType: 'condition', // not getting used currently
      });
    } catch (error) {
      handleError(error);
    }
  };

  const getUiConfig = () => (
    customUiConfig && Object.keys(customUiConfig).length > 0 ?
      customUiConfig :
      defaultUiConfig
  );
  const getTextConfig = () => (
    customTextConfig && Object.keys(customTextConfig).length > 0 ?
      customTextConfig :
      defaultTextConfig
  );

  const addNewNode = (node) => {
    closeAddNodeModal();
    const { parent, child } = addNodeBetween;
    const action = {
      operation: operations.ADD_MODULE,
      actionData: {
        node,
        addNodeBetween: {
          parent, child,
        },
        nodes,
        countryDocMapping,
        localOrderOfNodes,
        defaultFormSections,
      },
    };
    let response = {};
    try {
      response = updateWorkflowInState({}, true, action);
    } catch (error) {
      handleError(error);
      return;
    }
    const { success, extraData } = response;
    if (success) {
      const { highLevelUiConfig, newModule, highLevelTextConfig } = extraData;
      // add UI configs as well
      if (Object.keys(highLevelUiConfig || {}).length) {
        const currentUiConfig = getUiConfig();
        const updatedHighLevelUiConfig = setDefaultUIConfigsForSuperModule(
          currentUiConfig,
          highLevelUiConfig,
        );
        // set uiConfigSource to custom
        try {
          updateWorkflowInState({}, true, {
            operation: operations.SET_WORKFLOW_ATTRIBUTE,
            actionData: {
              path: 'properties.uiConfigSource',
              value: 'custom',
            },
          });
          updateWorkflowInState({}, true, {
            operation: operations.OVERWRITE_UI_CONFIG,
            actionData: {
              uiConfig: updatedHighLevelUiConfig,
            },
          });
        } catch (error) {
          handleError(error);
        }
      }
      if (Object.keys(highLevelTextConfig || {}).length) {
        const currentTextConfig = getTextConfig();
        const updatedTextConfig =
        setDefaultTextConfigsForSuperModule(currentTextConfig, highLevelTextConfig);
        try {
          updateWorkflowInState({}, true, {
            operation: operations.SET_WORKFLOW_ATTRIBUTE,
            actionData: {
              path: `properties.textConfigSource.${selectedLanguage}`,
              value: 'custom',
            },
          });
          dispatch(
            updateCustomTextConfig({ textConfig: updatedTextConfig, language: selectedLanguage }),
          );
        } catch (error) {
          handleError(error);
        }
      }

      setNewNodeAdded(newModule.id);
    }
    // TODO: How are we ensuring that the workflow will be updated before selecting the new module?
  };

  const addNewCondition = () => {
    const { parent, child } = addNodeBetween;
    const action = {
      operation: operations.ADD_CONDITION,
      actionData: {
        addNodeBetween: {
          parent, child,
        },
        nodes,
        localOrderOfNodes,
      },
    };
    let response = {};
    try {
      response = updateWorkflowInState({}, true, action);
    } catch (error) {
      handleError(error);
      return;
    }
    closeAddNodeModal();
    const { success, extraData } = response;
    if (success) {
      const { newNodeId: newConditionId } = extraData;
      setNewNodeAdded(newConditionId);
    }
  };

  const onNodeClick = (_event, node) => {
    let DRAWER_TYPE;
    if (node.nodeType === 'start') {
      DRAWER_TYPE = DRAWER_TYPES.start;
    } else if (node.nodeType === 'goto') {
      DRAWER_TYPE = DRAWER_TYPES.goto;
    } else {
      const selectedModule = versionedModules[node.nodeType];
      const properties = selectedModule?.[selectedModule?.latestVersion]?.config;
      DRAWER_TYPE = properties?.DRAWER_TYPE || DRAWER_TYPES.none;
    }
    edges.forEach((edge) => {
      const edgeElement = document.getElementById(`${edge.id}_button_edge`);
      if (edge.source === node.id && edgeElement) {
        edgeElement.removeAttribute('class');
        edgeElement.classList.add('active-condition');
      } else if (edgeElement) {
        edgeElement.removeAttribute('class');
        edgeElement.classList.add('branch-name');
      }
    });
    dispatch(updateSelectedNode({ selectedNode: node }));
    setDrawerType(DRAWER_TYPE);
  };

  const edgeTypes = useMemo(() => (
    {
      conditionEdge: ConditionEdge,
      moduleEdge: ModuleEdge,
    }), []);

  const nodeTypes = useMemo(() => (
    {
      moduleNode: ModuleNode,
      approveNode: ApproveNode,
      autoApproveNode: ApproveNode,
      declineNode: DeclineNode,
      autoDeclineNode: DeclineNode,
      conditionNode: ConditionNode,
      startNode: StartNode,
      reviewNode: ReviewNode,
      needsReviewNode: ReviewNode,
      gotoNode: GotoNode,
      dismissToParentNode: DismissNode,
      userCancelledNode: CancelledNode,
    }), []);

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handleNavigateModule = (value) => {
    const currentselectedNode = nodes.find((node) => node.id === value.id);
    onNodeClick(null, currentselectedNode);
  };

  const highlightedNodes = useMemo(
    () => {
      if (!searchValue.trim()) {
        return [];
      }

      return nodes.filter(
        (node) => (node.name?.toLowerCase().includes(searchValue.toLowerCase())
          || node.id?.toLowerCase() === searchValue.toLowerCase()) && node.name.toLowerCase() !== 'start',
      );
    },
    [searchValue, nodes],
  );

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      if (searchValue) {
        setSearchValue('');
        setIsEscapePressed(true);
        resetDrawerType();
      }
    } else {
      setIsEscapePressed(false);
    }
  };

  useEffect(() => {
    const handleKeyDownWrapper = (e) => handleKeyDown(e);

    window.addEventListener('keydown', handleKeyDownWrapper);
    return () => {
      window.removeEventListener('keydown', handleKeyDownWrapper);
      setIsEscapePressed(false);
    };
  }, [searchValue]);

  const getEditableWorkflowDetails = () => {
    if (!workflowConfig) return {};
    return ({
      name: workflowName,
      description: workflowDescription,
      moduleBuilderSubType: moduleBuilderMode
        ? (workflowConfig?.properties?.moduleBuilder?.subType || '')
        : null,
    });
  };

  const handleWorkflowUpdated = (workflowData) => {
    updateWorkflowInState({}, true, {
      operation: operations.UPDATE_WORKFLOW_DETAILS,
      actionData: {
        name: workflowData.name,
        description: workflowData.description,
        subType: workflowData.moduleBuilderSubType || null,
      },
    });
    setShowEditWorkflowDetailsModal(false);
  };

  useEffect(() => {
    localOrderOfNodes = [...orderOfNodes];
  }, [orderOfNodes]);

  const getModulesWithUpdates = (versionedModuleList, workflow = {}) => {
    const { modules = [] } = workflow;
    const modulesWithUpdates = modules.filter(({ version = 'v1', subType }) => subType !== 'countries' && version !== versionedModuleList?.[subType]?.latestVersion) || [];
    return modulesWithUpdates;
  };

  useEffect(() => {
    const modulesWithUpdates = getModulesWithUpdates(versionedModules, workflowConfig);
    setModulesTobeUpdated(modulesWithUpdates);
  }, [workflowConfig, versionedModules]);

  useEffect(() => {
    setWorkflowName(workflowConfig?.properties?.name || workflowConfig?.name || 'Workflow');
    setWorkflowDescription(workflowConfig?.properties?.description || workflowConfig?.description || 'Workflow');
    const converted = convertToNodesEdges(workflowConfig);
    dispatch(updateOrderOfNodes({ nodes: converted.nodes }));
    const uiAddedNodes = addUIPropertiesToNodes(
      converted.nodes,
      versionedModules,
      deleteCondition,
      showEditNodeModal,
      showDeleteNodeModal,
    );
    setNodes(uiAddedNodes);
    const uiAddedEdges = addUIPropertiesEdges(converted.edges);
    setEdges(uiAddedEdges);
    if (newNodeAdded) {
      const newNode = uiAddedNodes.find((node) => node.id === newNodeAdded);
      onNodeClick(null, newNode);
      setNewNodeAdded(null);
    }
    if (selectedNode && Object.keys(selectedNode).length) {
      const isOldSelectedNodeAvailable = converted.nodes
        .find((node) => node.id === selectedNode.id);
      if (isOldSelectedNodeAvailable) return;
      // Else goto node's module was updated
      const oldSelectedNodeIndex = orderOfNodes.findIndex((node) => node.id === selectedNode.id);
      if (converted.nodes[oldSelectedNodeIndex]) {
        dispatch(updateSelectedNode({ selectedNode: converted.nodes[oldSelectedNodeIndex] }));
      }
    }
  }, [workflowConfig, newNodeAdded]);

  let drawerContent = (
    <DefaultDrawer
      workflowConfig={workflowConfig}
      workflowId={workflowId}
      workflowName={workflowName}
    />
  );
  if (drawerType === DRAWER_TYPES.start) {
    drawerContent = (
      <StartDrawer workflowConfig={workflowConfig} />
    );
  } else if (drawerType === DRAWER_TYPES.goto) {
    const items = getConditionAndModuleItems(workflowConfig);
    drawerContent = (
      <GotoDrawer
        workflowConfig={workflowConfig}
        selectedNode={selectedNode}
        items={items}
        updateGoto={
          (parentNodeId, parentBranch, updatedGotoModule) => (
            updateGoto(parentNodeId, parentBranch, updatedGotoModule, workflowConfig)
          )
        }
      />
    );
  } else if (drawerType === DRAWER_TYPES.api_drawer) {
    drawerContent = (
      <GenericModuleDrawer
        selectedNode={selectedNode}
        workflowConfig={workflowConfig}
      />
    );
  } else if (drawerType === DRAWER_TYPES.condition) {
    drawerContent = (
      <NewBaseDrawer
        workflowId={workflowId}
      />
    );
  } else if (drawerType === DRAWER_TYPES.output) {
    drawerContent = (
      <OutputDrawer selectedNode={selectedNode} />
    );
  } else if (drawerType === 'new') {
    drawerContent = (
      <NewBaseDrawer
        workflowId={workflowId}
      />
    );
  }

  const goBack = () => {
    // TODO: fix isEdited at all places
    let shouldGoBack = true;
    if (isWorkflowEdited) {
      // TODO: show modal for this alert
      shouldGoBack = window.confirm('Save the changes or it will be lost forever!');
    }
    if (shouldGoBack) {
      navigate('/');
    }
  };

  return (
    <>
      {addNodeBetween
        ? (
          <AddNodeModal
            onClose={closeAddNodeModal}
            addNewNode={addNewNode}
            addNewCondition={addNewCondition}
          />
        )
        : null}
      {
        editEndStateParent
          ? (
            <EditNodeModal
              onClose={() => setEditEndStateParent(null)}
              onEditEndState={onEditEndState}
            />

          )
          : null
      }
      {
        showGotoModal
          ? (
            <Modal isOpen={Object.keys(showGotoModal || []).length} onClose={() => setShowGotoModal(null)} headerText="Select the module to Go To:">
              {displayGotoModal()}
            </Modal>
          )
          : null
      }
      {
        showEditWorkflowDetailsModal ? (
          <UpdateModal
            onUpdateWorkflow={(workflowData) => handleWorkflowUpdated(workflowData)}
            initialData={getEditableWorkflowDetails()}
            onClose={() => setShowEditWorkflowDetailsModal(false)}
            allowEditSubType={moduleBuilderMode}
          />
        ) : null
      }

      <div className="view_workflow__container">
        {moduleBuilderMode ? 'Module Builder Mode is ON!!!!!' : null}
        <Grid container className="view_workflow_fullHeading">
          <Grid item xs={4} className="view_workflow__heading_container">
            <ArrowBackIcon onClick={goBack} sx={{ color: 'rgba(5, 5, 82, 0.5)' }} />
            <div className="view_workflow__heading">
              <div className="view_workflow__heading_Container">
                <div className="view_workflow__heading_name">
                  {workflowName}
                </div>
                <div>
                  <button type="submit" onClick={(e) => handleEditWorkflow(e)} id="workflow_table__renameEditIcon">
                    <img src={renameEditIcon} alt="renameEditIcon" />
                  </button>
                </div>
              </div>
              <div className="view_workflow__heading_id">
                {`Workflow ID: ${workflowId}`}
              </div>
            </div>
          </Grid>

          <Grid item xs={8} className="try-workflow__publish-workflow">
            <Search
              searchValue={searchValue}
              handleSearchChange={handleSearchChange}
              highlightedNodes={highlightedNodes}
              selectedNodeId={selectedNode.id}
              selectedNodeName={selectedNode.name}
              handleNavigateModule={handleNavigateModule}
            />
            {!moduleBuilderMode && (
              <ModuleVersionUpdate
                modulesTobeUpdated={modulesTobeUpdated}
                versionedModules={versionedModules}
                workflow={workflowConfig}
                countryDocMapping={countryDocMapping}
                localOrderOfNodes={localOrderOfNodes}
                defaultFormSections={defaultFormSections}
              />
            )}
            <TryWorkflow
              workflowId={workflowId}
              allowedToShowDropDown={showDropDownFor === 'tryWorkflow'}
              showDropdown={(show) => setShowDropDownFor(show ? 'tryWorkflow' : '')}
              moduleBuilderMode={moduleBuilderMode}
            />
            <PublishWorkflow />
            <MoreWorkflowOptions
              allowedToShowDropDown={showDropDownFor === 'moreWorkflowOptions'}
              showDropdown={(show) => setShowDropDownFor(show ? 'moreWorkflowOptions' : '')}
            />
          </Grid>
        </Grid>
        <div className="view_workflow__body_container">
          <div className="view_workflow__workflow_container">
            {nodes.length && edges.length ? (
              <ReactFlowProvider>
                <ELKLayout
                  initialNodes={nodes}
                  initialEdges={edges}
                  edgeTypes={edgeTypes}
                  nodeTypes={nodeTypes}
                  onNodeClick={onNodeClick}
                  resetDrawerType={resetDrawerType}
                  highlightedNodes={highlightedNodes}
                  isEscapePressed={isEscapePressed}
                  searchValue={searchValue}
                />
              </ReactFlowProvider>
            ) : ''}
          </div>
          {drawerContent}
        </div>
      </div>
    </>
  );
}

// ViewWorkflow.propTypes = {
//   checkDependencies: PropTypes.func.isRequired,
// };

export default withDeletionDependencyCheck(PermissionWrapper(ViewWorkflow, useGetUserPermissions, getPermission('workflowDetailsPage')));
