import PropTypes from 'prop-types';
import DropDown from '../ViewWorkflow/DropDown';

function NavigationSection({
  nextStepOptions,
  handleNextStepChange,
  selectedAction,
}) {
  return (
    <div className="configure-event__navigation-section">
      <div className="configure-event__control-group">
        <div className="configure-event__control-group-label">Destination</div>
        <div className="configure-event__control-group-dropdown">
          <DropDown
            items={{ items: nextStepOptions }}
            onChange={handleNextStepChange}
            noSelectLabel="Select destination"
            defaultValue={selectedAction}
            allowCustomInput
            textBoxPlaceholder="value"
            maxLength={30}
          />
        </div>
      </div>
    </div>
  );
}

NavigationSection.propTypes = {
  nextStepOptions: PropTypes.array.isRequired,
  handleNextStepChange: PropTypes.func.isRequired,
  selectedAction: PropTypes.string.isRequired,
};

export default NavigationSection;
