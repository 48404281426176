import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popper from '@mui/material/Popper';
import ConfigureEventsV2Modal from '../../containers/FormModule/ConfigureEventsV2Modal';
import moreIcon from '../../assests/icons/moreOptions.svg';

function EventList({
  processedEvents,
  handleButtonClick,
  handleMoreButtonClick,
  isComponentOpen,
  selectedEventData,
  eventDropdownOptions,
  reloadDropdownOptions,
  handleClose,
  setSelectedEventData,
}) {
  const eventItemRefs = useRef({});
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (selectedEventData) {
      setAnchorEl(eventItemRefs.current[selectedEventData.eventId]);
    }
  }, [selectedEventData]);

  return (
    <div className="events-panel__list">
      {Object.entries(processedEvents || {}).map(([eventId, event]) => (
        Object.entries(event).map(([eventType, eventData]) => (
          <div key={eventId}>
            <div
              className="events-panel__list-item"
              ref={(ref) => { eventItemRefs.current[eventId] = ref; }}
              onClick={() => {
                handleButtonClick(eventId, eventData, eventType);
                setAnchorEl(eventItemRefs.current[eventId]);
              }}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              <div className="events-panel__list-item-event-name">
                {eventType}
              </div>
              <div className="events-panel__list-item-event-action-container">
                <div className="events-panel__list-item-event-action">
                  {
                  eventData?.actions?.[0] &&
                  Object.keys(eventData?.actions?.[0]?.operations)[0]
                  }
                </div>
                <button
                  type="button"
                  className="events-panel__list-item-event-action-more"
                  onClick={(e) => handleMoreButtonClick(e, eventId, eventData, eventType)}
                  onKeyDown={(e) => e.key === 'Enter' && handleMoreButtonClick(e, eventId, eventData, eventType)}
                  tabIndex={0}
                >
                  <img src={moreIcon} alt="More" />
                </button>
              </div>
            </div>

            <Popper
              open={isComponentOpen && selectedEventData?.eventId === eventId}
              anchorEl={anchorEl}
              placement="left-start"
              modifiers={[
                {
                  name: 'flip',
                  enabled: false,
                },
              ]}
              style={{ position: 'absolute', zIndex: 1100 }}
            >
              <div style={{ position: 'relative' }}>
                {isComponentOpen && selectedEventData?.eventId === eventId && (
                  <ConfigureEventsV2Modal
                    selectedEventData={selectedEventData}
                    setSelectedEventData={setSelectedEventData}
                    eventDropdownOptions={eventDropdownOptions}
                    reloadDropdownOptions={reloadDropdownOptions}
                    onClose={handleClose}
                  />
                )}
              </div>
            </Popper>
          </div>
        ))
      ))}
    </div>
  );
}

EventList.propTypes = {
  processedEvents: PropTypes.object.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  handleMoreButtonClick: PropTypes.func.isRequired,
  isComponentOpen: PropTypes.bool.isRequired,
  selectedEventData: PropTypes.object,
  eventDropdownOptions: PropTypes.array.isRequired,
  reloadDropdownOptions: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  setSelectedEventData: PropTypes.func.isRequired,
};

EventList.defaultProps = {
  selectedEventData: null,
};

export default EventList;
