import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import FormModuleEditPropertiesV2 from '../../components/FormModule/FormModuleEditPropertiesV2';
import ListFormModuleV2 from '../../components/FormModule/ListFormModuleV2';
import { attachBorder } from '../../utils/domSdkEffects';
import { getFormComponentsFromHtmlString, getFormHtmlStringForV2, getTagNameById } from './helper';

function FormModuleDrawerV2({
  refreshMap,
  formModule,
  formV2Components,
  onAddComponent,
  onDeleteComponent,
  onCloneComponent,
  onCopyComponent,
  onUpdateComponentTagName,
  onDragComponent,
  onUpdateComponent,
  onPasteComponent,
  isFormPartOfSuperModule,
}) {
  const [selectedItem, setSelectedItem] = useState({
    basePath: '',
    componentId: '',
  });
  const [reloadDropdownOptions, setReloadDropdownOptions] = useState([]);
  const [eventDropdownOptions, setEventDropdownOptions] = useState([]);

  const onItemSelect = (componentId, basePath) => {
    setSelectedItem({ basePath, componentId });
  };

  useEffect(() => {
    const componentReferenceList = Object.entries(formModule.properties.componentConfigs)
      .filter(([, component]) => component?.componentRef?.libraryId
      && component?.componentRef?.formComponentId)
      .map(([componentId, component]) => ({
        id: componentId,
        name: component.name,
        libraryId: component.componentRef.libraryId,
        formComponentId: component.componentRef.formComponentId,
      }));

    const componentConfigurationsById = componentReferenceList
      .reduce((acc, {
        id, libraryId, formComponentId, name,
      }) => {
        const matchingComponent = formV2Components[libraryId]?.components
          ?.find((comp) => comp.id === formComponentId);

        if (matchingComponent) {
          const getConfigsByType = (type) => matchingComponent.configurations
            .filter((config) => config.type === type)
            .map((config) => config.key);

          acc[id] = {
            properties: getConfigsByType('properties'),
            attributes: getConfigsByType('attributes'),
            styles: getConfigsByType('styles'),
            events: matchingComponent.events || [],
          };

          acc[id].sdkControllers = ['visible'];
          acc[id].name = name;
        }
        return acc;
      }, {});

    // Set available events based on selected component
    const selectedComponentEvents = componentConfigurationsById[selectedItem.componentId]
      ?.events?.map((event) => ({
        label: 'Events',
        items: Object.keys(event).map((eventName) => ({
          label: eventName,
          value: `${eventName}`,
          displayLabel: `${eventName}`,
        })),
        disabled: false,
      })) || [];
    setEventDropdownOptions(selectedComponentEvents);

    // Create reloadDropdownOptions based on the configurations
    const createReloadDropdownOptions = (comp) => Object.entries(comp).map(([id, config]) => {
      const configTypes = ['properties', 'attributes', 'styles', 'sdkControllers'];
      const items = configTypes
        .filter((type) => config[type]?.length)
        .map((type) => ({
          label: type,
          items: config[type].map((item) => ({
            label: item,
            value: `${id}.${type}.${item}`,
            displayLabel: `${config.name} > ${type} > ${item}`,
          })),
          disabled: false,
        }));

      return {
        label: config.name,
        items,
        disabled: false,
      };
    });
    setReloadDropdownOptions(createReloadDropdownOptions(componentConfigurationsById));
  }, [formModule.properties.componentConfigs, formV2Components, selectedItem.componentId]);

  useEffect(() => {
    const wrapperClassName = 'hv-builder-click-border-wrapper';
    const borderStyle = '1px dotted rgb(24, 129, 24)';
    attachBorder(selectedItem.componentId, wrapperClassName, borderStyle);
  }, [selectedItem]);

  const getComponentLists = (module) => {
    const htmlString = getFormHtmlStringForV2(module);
    const formComponents = getFormComponentsFromHtmlString(htmlString);
    return [
      {
        id: 'someId',
        title: 'COMPONENT LIST V3',
        components: formComponents,
        // components: [{
        //   id: 'testId',
        //   title: 'Item text',
        //   isContainer: true,
        //   subComponents: [
        //     {
        //       tagName: 'tagName2',
        //       id: 'textId2',
        //       text: 'Item text 2',
        //     },
        //   ],
        // }],
        enableAddButton: isFormPartOfSuperModule !== true,
        enableCopyButton: isFormPartOfSuperModule !== true,
        enableDeleteButton: isFormPartOfSuperModule !== true,
        enablePasteButton: isFormPartOfSuperModule !== true,
        enableDrag: isFormPartOfSuperModule !== true,
      }];
  };

  const handleOnClick = (componentId, basePath) => {
    onItemSelect(componentId, basePath);
  };

  const handleOnDrag = (pickId, dropId) => {
    onDragComponent(pickId, dropId);
  };

  const handleOnDelete = (componentId) => {
    onItemSelect('', '');
    onDeleteComponent(componentId);
  };

  const getSelectedComponent = (
    moduleProperties,
    workflowKey,
  ) => get(moduleProperties, workflowKey, {});

  const getComponentName = (module, componentId) => {
    const component = get(module.properties.componentConfigs, componentId);
    return component?.name || '';
  };

  return (
    <div className="master">
      <div className="component-list-div">
        <ListFormModuleV2
          componentLists={getComponentLists(formModule)}
          getComponentName={(componentId) => getComponentName(formModule, componentId)}
          selectedComponentLocation={selectedItem}
          handleOnClick={handleOnClick}
          handleOnDelete={(componentId) => handleOnDelete(componentId)}
          handleOnCopy={(componentId) => onCopyComponent(componentId)}
          handleOnClone={(componentId) => onCloneComponent(componentId)}
          handleOnAdd={(componentId) => onAddComponent(componentId)}
          handleOnDrag={(pickId, dropId) => handleOnDrag(pickId, dropId)}
          handleOnPaste={() => { onPasteComponent(); }}
        />
      </div>
      {
        selectedItem.componentId !== '' ? (
          <FormModuleEditPropertiesV2
            formComponentLibraries={formV2Components}
            eventDropdownOptions={eventDropdownOptions}
            reloadDropdownOptions={reloadDropdownOptions}
            refreshMap={refreshMap}
            selectedComponentTagName={getTagNameById(formModule, selectedItem?.componentId)}
            selectedModuleId={formModule?.id}
            selectedComponent={getSelectedComponent(
              formModule?.properties,
              `componentConfigs['${selectedItem?.componentId}']`,
            )}
            selectedComponentId={selectedItem?.componentId}
            onUpdateComponent={(property, value) => {
              onUpdateComponent({
                componentId: selectedItem?.componentId,
                property,
                value,
              });
            }}
            isFormPartOfSuperModule={isFormPartOfSuperModule === true}
            onTagNameUpdate={(
              newTagName,
              libraryUrl,
              styleSheetsUrl,
              defaults,
              componentRef,
            ) => {
              onUpdateComponentTagName(
                selectedItem?.componentId,
                newTagName,
                libraryUrl,
                styleSheetsUrl,
                defaults,
                componentRef,
              );
            }}
          />
        ) : (
          <div className="edit-properties-div">
            <div className="edit-properties-div__property">
              <p>Please select a component</p>
            </div>
          </div>
        )
      }
    </div>
  );
}

FormModuleDrawerV2.propTypes = {
  formModule: PropTypes.object.isRequired,
  formV2Components: PropTypes.object.isRequired,
  onAddComponent: PropTypes.func.isRequired,
  onDeleteComponent: PropTypes.func.isRequired,
  onCloneComponent: PropTypes.func.isRequired,
  onCopyComponent: PropTypes.func.isRequired,
  onUpdateComponentTagName: PropTypes.func.isRequired,
  onDragComponent: PropTypes.func.isRequired,
  onUpdateComponent: PropTypes.func.isRequired,
  onPasteComponent: PropTypes.func.isRequired,
  isFormPartOfSuperModule: PropTypes.bool.isRequired,
  refreshMap: PropTypes.object.isRequired,
};

export default FormModuleDrawerV2;
