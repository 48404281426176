import {
  useState, useEffect, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep, set, unset } from 'lodash';
import './ConfigureEventsV2Modal.scss';
import generateUniqueID from '../../utils/generateUniqueId';
import DropDown from '../../components/ViewWorkflow/DropDown';
import {
  selectOrderOfNodes, selectSelectedWorkflow, selectVersionedModules, selectSelectedNode,
} from '../../reducers/workflow';
import { formComponentList as formComponents } from '../../constants/dynamicFormComponents';
import { getModuleOutputs, getConditionalVariables, getWorkflowInputVariables } from '../../components/ViewWorkflow/InputsToModule/utils';
import { createNestedMenuData } from '../../components/ViewWorkflow/NestedDropdown/utils';
import { getNextStepOptions, flattenObject } from '../../components/FormModule/utils';
import NavigationSection from '../../components/FormModule/NavigationSection';
import RuleConfigurationSection from '../../components/FormModule/RuleConfigurationSection';
import ConfigureTargetSection from '../../components/FormModule/ConfigureTargetSection';
import ConfigureEventsV2ModalHeader from '../../components/FormModule/ConfigureEventsV2ModalHeader';
import ConfigureEventsV2ModalEmptyState from '../../components/FormModule/ConfigureEventsV2ModalEmptyState';
import addMenuOptions from '../../constants/addMenuOptions';
import changeActionMenuOptions from '../../constants/changeActionOptions';
import ActionsRenderer from '../../components/FormModule/ActionsRenderer';

function ConfigureEventsV2Modal({
  selectedEventData,
  setSelectedEventData,
  eventDropdownOptions,
  reloadDropdownOptions,
  onClose,
}) {
  const orderOfModules = useSelector(selectOrderOfNodes);
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const versionedModules = useSelector(selectVersionedModules);
  const selectedNode = useSelector(selectSelectedNode);
  const selectedNodeId = selectedNode?.id;
  const nextSteps = getNextStepOptions(orderOfModules);
  const [eventsV2, setEventsV2] = useState(() => ({
    [selectedEventData.eventId]: {
      [selectedEventData.eventType]: selectedEventData.eventData,
    },
  }));

  const menuOptions = useMemo(() => {
    const moduleOutputOptions = getModuleOutputs(
      orderOfModules,
      selectedNodeId,
      selectedWorkflow,
      formComponents,
      versionedModules,
    );
    const conditionalVariableOptions = getConditionalVariables(selectedWorkflow);
    const workflowInputOptions = getWorkflowInputVariables(selectedWorkflow);
    return createNestedMenuData(
      workflowInputOptions,
      conditionalVariableOptions,
      moduleOutputOptions,
    );
  }, [
    versionedModules,
    orderOfModules,
    selectedNodeId,
    selectedWorkflow,
  ]);

  const nextStepOptions = [
    {
      label: 'Next Steps',
      items: Array.from(new Set(nextSteps.map((step) => step.name)))
        .map((name) => {
          const step = nextSteps.find((s) => s.name === name);
          return {
            label: name,
            value: step.key,
            displayLabel: name,
          };
        }),
      disabled: false,
    },
  ];
  const [selectedAction, setSelectedAction] = useState({});
  const [targetValuePairs, setTargetValuePairs] = useState([{
    id: generateUniqueID(),
    target: '',
    value: '',
  }]);

  const [isRenaming, setIsRenaming] = useState(null);
  const [isDragging, setIsDragging] = useState(null);
  const [dragOverId, setDragOverId] = useState(null);
  const [draggedActionId, setDraggedActionId] = useState(null);

  const handleRuleChange = (newRule) => {
    const currentEventData = cloneDeep(eventsV2);
    set(currentEventData, `${selectedEventData.eventId}.${selectedEventData.eventType}.actions.${selectedAction.id}.rule`, newRule);
    setEventsV2(currentEventData);
  };
  const handleNextStepChange = (item) => {
    const currentEventData = cloneDeep(eventsV2);
    set(currentEventData, `${selectedEventData.eventId}.${selectedEventData.eventType}.actions.${selectedAction.id}.operations.${selectedAction.operationId}.nextStep`, item.value);
    setEventsV2(currentEventData);
  };
  const handleAddRule = () => {
    const newActionId = generateUniqueID();
    const maxOrder = Object.values(
      eventsV2[selectedEventData.eventId][selectedEventData.eventType].actions,
    ).reduce((max, action) => Math.max(max, action.order || 0), 0);
    const currentEventData = cloneDeep(eventsV2);
    set(currentEventData, `${selectedEventData.eventId}.${selectedEventData.eventType}.actions.${newActionId}`, {
      rule: 'Rule',
      name: 'Rule',
      order: maxOrder + 1,
      operations: {},
    });
    setEventsV2(currentEventData);
  };
  const handleActionNameChange = (actionId, newName) => {
    const currentEventData = cloneDeep(eventsV2);
    set(currentEventData, `${selectedEventData.eventId}.${selectedEventData.eventType}.actions.${actionId}.name`, newName);
    setEventsV2(currentEventData);
  };
  const handleAddAction = (actionType) => {
    const newActionId = generateUniqueID();
    const newOperationId = generateUniqueID();
    const defaultName = actionType === 'nextStep' ? 'Redirect to new page' : 'reload component A, B';

    // Get the highest existing order number
    const existingActions =
      eventsV2[selectedEventData.eventId][selectedEventData.eventType].actions || {};
    const maxOrder =
      Object.values(existingActions).reduce((max, action) => Math.max(max, action.order || 0), 0);
    const currentEventData = cloneDeep(eventsV2);
    set(currentEventData, `${selectedEventData.eventId}.${selectedEventData.eventType}.actions.${newActionId}`, {
      name: defaultName,
      order: maxOrder + 1,
      operations: {
        [newOperationId]: {
          [actionType]: actionType === 'nextStep' ? '' : {},
        },
      },
    });
    setEventsV2(currentEventData);
  };

  const handleEventChange = (event) => {
    if (selectedEventData.eventType !== event) {
      const id = selectedEventData.eventId;
      const existingEventData = selectedEventData.eventData;

      const value = {
        [event]: existingEventData,
      };
      const currentEventData = cloneDeep(eventsV2);
      set(currentEventData, `${id}`, value);
      setEventsV2(currentEventData);
      setSelectedEventData({
        eventId: id,
        eventType: event,
        eventData: existingEventData,
      });
    }
  };

  const handleRuleNameChange = (actionId, newName) => {
    const currentEventData = cloneDeep(eventsV2);
    set(currentEventData, `${selectedEventData.eventId}.${selectedEventData.eventType}.actions.${actionId}.name`, newName);
    setEventsV2(currentEventData);
  };

  const handleActionChange = (item) => {
    let value;
    if (item.value === 'nextStep') {
      value = {
        [item.value]: '',
      };
    } else {
      value = {
        [item.value]: {},
      };
    }
    const currentEventData = cloneDeep(eventsV2);
    set(currentEventData, `${selectedEventData.eventId}.${selectedEventData.eventType}.actions.${selectedAction.id}.operations.${selectedAction.operationId}`, value);
    setEventsV2(currentEventData);
  };
  const handleActionClick = (action) => {
    setSelectedAction({
      id: action.id,
      operationId: action.operationId,
    });
  };

  const handleRemoveAction = (actionId) => {
    // Create a deep copy of the current actions
    const currentActions = Object.fromEntries(
      Object.entries(
        eventsV2[selectedEventData.eventId][selectedEventData.eventType].actions,
      ).map(([key, value]) => [key, { ...value }]),
    );
    const removedOrder = currentActions[actionId].order;

    // Delete the action
    delete currentActions[actionId];

    // Reorder remaining actions
    Object.keys(currentActions).forEach((id) => {
      if (currentActions[id].order > removedOrder) {
        currentActions[id].order -= 1;
      }
    });
    const currentEventData = cloneDeep(eventsV2);
    set(currentEventData, `${selectedEventData.eventId}.${selectedEventData.eventType}.actions`, currentActions);
    setEventsV2(currentEventData);

    // Clear selection if the removed action was selected
    if (selectedAction?.id === actionId) {
      setSelectedAction(null);
    }
  };
  const handleAddOperation = (actionId, operationType) => {
    const newOperationId = generateUniqueID();
    const defaultName = operationType === 'nextStep' ? 'Navigate to new page' : 'Reload component';
    const currentEventData = cloneDeep(eventsV2);
    set(currentEventData, `${selectedEventData.eventId}.${selectedEventData.eventType}.actions.${actionId}.operations.${newOperationId}`, {
      name: defaultName,
      [operationType]: operationType === 'nextStep' ? '' : {},
    });
    setEventsV2(currentEventData);
  };

  const handleOperationNameChange = (actionId, operationId, newName) => {
    const currentEventData = cloneDeep(eventsV2);
    set(currentEventData, `${selectedEventData.eventId}.${selectedEventData.eventType}.actions.${actionId}.operations.${operationId}.name`, newName);
    setEventsV2(currentEventData);
  };

  const handleRemoveOperation = (actionId, operationId) => {
    const currentEventData = cloneDeep(eventsV2);
    unset(currentEventData, `${selectedEventData.eventId}.${selectedEventData.eventType}.actions.${actionId}.operations.${operationId}`);
    setEventsV2(currentEventData);
    if (selectedAction?.operationId === operationId) {
      setSelectedAction(null);
    }
  };

  const handleDragStart = (actionId) => {
    setDraggedActionId(actionId);
    setIsDragging(actionId);
  };

  const handleDrop = (targetActionId) => {
    if (draggedActionId && draggedActionId !== targetActionId) {
      const currentEventData = cloneDeep(eventsV2);
      const { actions } = currentEventData[selectedEventData.eventId][selectedEventData.eventType];

      const draggedAction = actions[draggedActionId];
      const targetAction = actions[targetActionId];

      const { order: draggedOrder } = draggedAction;
      const { order: targetOrder } = targetAction;

      const isMovingUp = draggedOrder > targetOrder;

      Object.keys(actions).forEach((actionId) => {
        const action = actions[actionId];
        const { order } = action;
        let newOrder = order;

        if (isMovingUp) {
          if (order >= targetOrder && order < draggedOrder) {
            newOrder += 1;
          }
        } else if (order > draggedOrder && order <= targetOrder) {
          newOrder -= 1;
        }

        // Create a new object with the updated order
        actions[actionId] = { ...action, order: newOrder };
      });

      // Update the dragged action's order
      actions[draggedActionId] = { ...draggedAction, order: targetOrder };

      setEventsV2(currentEventData);
    }
    setDraggedActionId(null);
    setIsDragging(null);
  };
  useEffect(() => {
    const reloadV2Data = eventsV2[selectedEventData.eventId][selectedEventData.eventType].actions
      ?.[selectedAction?.id]?.operations?.[selectedAction?.operationId]?.reloadV2;
    if (reloadV2Data) {
      const pairs = [];
      Object.entries(reloadV2Data).forEach(([componentId, config]) => {
        pairs.push(...flattenObject(config, componentId));
      });
      setTargetValuePairs(pairs.length > 0 ? pairs : [{
        id: generateUniqueID(),
        target: '',
        value: '',
      }]);
    } else {
      setTargetValuePairs([{
        id: generateUniqueID(),
        target: '',
        value: '',
      }]);
    }
  }, [selectedEventData, selectedAction]);
  useEffect(() => {
    const { actions } = eventsV2[selectedEventData.eventId][selectedEventData.eventType] || {};

    // Sort actions based on the order property
    const sortedActions = Object.entries(actions)
      .sort(([, a], [, b]) => a.order - b.order)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    // Update the eventsV2 with sorted actions
    const updatedEventData = cloneDeep(eventsV2);
    set(updatedEventData, `${selectedEventData.eventId}.${selectedEventData.eventType}.actions`, sortedActions);
    setEventsV2(updatedEventData);
  }, [draggedActionId]);
  useEffect(() => {
    if (selectedAction?.id && selectedAction?.operationId) {
      const currentEventData = cloneDeep(eventsV2);
      const currentOperation =
      currentEventData[selectedEventData.eventId][selectedEventData.eventType]
        .actions[selectedAction.id].operations[selectedAction.operationId];

      // Only update if this is a reloadV2 operation
      if (currentOperation.reloadV2 !== undefined) {
        const reloadV2Data = {};
        targetValuePairs.forEach(({ target, value }) => {
          if (target) {
            set(reloadV2Data, target, value);
          }
        });

        set(
          currentEventData,
          `${selectedEventData.eventId}.${selectedEventData.eventType}.actions.${selectedAction.id}.operations.${selectedAction.operationId}.reloadV2`,
          reloadV2Data,
        );
        setEventsV2(currentEventData);
      }
    }
  }, [targetValuePairs]);
  const renderActions = () => (
    <ActionsRenderer
      actions={eventsV2[selectedEventData.eventId][selectedEventData.eventType].actions || {}}
      isDragging={isDragging}
      dragOverId={dragOverId}
      selectedAction={selectedAction}
      handleDragStart={handleDragStart}
      handleDrop={handleDrop}
      setDragOverId={setDragOverId}
      setIsDragging={setIsDragging}
      handleRuleNameChange={handleRuleNameChange}
      handleRemoveAction={handleRemoveAction}
      handleActionClick={handleActionClick}
      handleOperationNameChange={handleOperationNameChange}
      handleRemoveOperation={handleRemoveOperation}
      handleAddOperation={handleAddOperation}
      handleActionNameChange={handleActionNameChange}
      setIsRenaming={setIsRenaming}
      isRenaming={isRenaming}
    />
  );

  const renderRightPanelContent = () => {
    if (selectedAction && eventsV2[selectedEventData.eventId][selectedEventData.eventType]
      .actions[selectedAction.id]?.rule && selectedAction.operationId === null) {
      return (
        <div className="configure-event__rule-config">
          <RuleConfigurationSection
            rule={eventsV2[selectedEventData.eventId][selectedEventData.eventType]
              .actions[selectedAction.id].rule}
            menuOptions={menuOptions}
            handleRuleChange={handleRuleChange}
          />
        </div>
      );
    }

    if (selectedAction && Object.keys(selectedAction).length > 0) {
      return (
        <div className="configure-event__action-config">
          <div className="configure-event__control-group">
            <div className="configure-event__control-group-label">Action</div>
            <div className="configure-event__control-group-dropdown">
              <DropDown
                items={{ items: changeActionMenuOptions }}
                onChange={handleActionChange}
                noSelectLabel="Add Action or Rule"
                defaultValue={Object.keys(
                  eventsV2[selectedEventData.eventId][selectedEventData.eventType]
                    .actions[selectedAction.id].operations[selectedAction.operationId] || {},
                ).filter((key) => key !== 'name')[0]}
                hideSelection
                key={selectedAction?.operationId}
              />
            </div>
          </div>

          {eventsV2[selectedEventData.eventId][selectedEventData.eventType]
            .actions[selectedAction.id].operations[selectedAction.operationId].reloadV2 ? (
              <ConfigureTargetSection
                key={selectedAction.operationId}
                targetValuePairs={targetValuePairs}
                setTargetValuePairs={setTargetValuePairs}
                reloadDropdownOptions={reloadDropdownOptions}
                menuOptions={menuOptions}
              />
            ) : (
              <NavigationSection
                key={selectedAction.operationId}
                nextStepOptions={nextStepOptions}
                handleNextStepChange={handleNextStepChange}
                selectedAction={eventsV2[selectedEventData.eventId][selectedEventData.eventType]
                  .actions[selectedAction.id].operations[selectedAction.operationId].nextStep}
              />
            )}
        </div>
      );
    }

    return null;
  };

  const handleAddMenuSelection = (item) => {
    if (item.value === 'rule') {
      handleAddRule();
    } else if (item.value === 'nextStep' || item.value === 'reloadV2') {
      handleAddAction(item.value);
    }
  };

  return (
    <div className="configure-event">
      <ConfigureEventsV2ModalHeader
        eventDropdownOptions={eventDropdownOptions}
        selectedEventData={selectedEventData}
        handleEventChange={handleEventChange}
        onClose={onClose}
        eventsV2={eventsV2}
      />

      <div className="configure-event__content">
        {(!eventsV2[selectedEventData.eventId][selectedEventData.eventType].actions
          || Object.keys(
            eventsV2[selectedEventData.eventId][selectedEventData.eventType].actions,
          ).length === 0) ? (
            <ConfigureEventsV2ModalEmptyState
              addMenuOptions={addMenuOptions}
              handleAddMenuSelection={handleAddMenuSelection}
            />
          ) : (
            <>
              <div className="configure-event__left-panel">
                <div className="rules-section">
                  <div className="auto-height-section">
                    {renderActions()}
                  </div>
                </div>

                <div className="bottom-actions">
                  <div className="bottom-actions__add-menu-container">
                    <DropDown
                      items={{ items: addMenuOptions }}
                      onChange={handleAddMenuSelection}
                      noSelectLabel="Add Action or Rule"
                      defaultValue=""
                      hideSelection
                    />
                  </div>
                </div>
              </div>

              <div className="configure-event__right-panel">
                {renderRightPanelContent()}
              </div>
            </>
          )}
      </div>
    </div>
  );
}

ConfigureEventsV2Modal.propTypes = {
  eventDropdownOptions: PropTypes.array.isRequired,
  reloadDropdownOptions: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedEventData: PropTypes.object.isRequired,
  setSelectedEventData: PropTypes.func.isRequired,
};

export default ConfigureEventsV2Modal;
