import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Popper from '@mui/material/Popper';
import editIcon2 from '../../assests/icons/editIcon2.svg';
import ConditionsDrawer from '../Conditions/ConditionsDrawer';

function RuleConfigurationSection({
  rule,
  menuOptions,
  handleRuleChange,
}) {
  const [showRuleDrawer, setShowRuleDrawer] = useState(false);
  const anchorRef = useRef(null);

  const handleConditionSave = (value) => {
    handleRuleChange(value);
    setShowRuleDrawer(false);
  };

  return (
    <div className="configure-event__action-config">
      <div className="configure-event__control-group">
        <div className="configure-event__control-group-label">Rule</div>
        <div className="configure-event__rule-input-container">
          <div className="configure-event__rule-input">
            {rule || ''}
            <button
              type="button"
              onClick={() => setShowRuleDrawer(true)}
              className="configure-event__rule-edit-button"
              ref={anchorRef}
            >
              <img src={editIcon2} alt="edit" />
            </button>
          </div>
        </div>
      </div>

      {showRuleDrawer && (
        <div className="popper-wrapper">
          <Popper
            open={showRuleDrawer}
            anchorEl={anchorRef.current}
            placement="right-start"
            sx={{
              transform: 'none !important',
            }}
          >
            <div className="condition_drawer__overlay">
              <div className="condition_drawer__modal">
                <ConditionsDrawer
                  onClose={() => setShowRuleDrawer(false)}
                  rule={rule === 'Rule' ? '' : rule}
                  dropDownOptions={menuOptions.items}
                  onConditionSave={handleConditionSave}
                  allowDefaultRules={false}
                  preDefinedValues={[]}
                />
              </div>
            </div>
          </Popper>
        </div>
      )}
    </div>
  );
}

RuleConfigurationSection.propTypes = {
  rule: PropTypes.object.isRequired,
  menuOptions: PropTypes.object.isRequired,
  handleRuleChange: PropTypes.func.isRequired,
};

export default RuleConfigurationSection;
