import PropTypes from 'prop-types';
import DropDown from '../ViewWorkflow/DropDown';
import newAddIcon from '../../assests/icons/add.svg';
import generateUniqueID from '../../utils/generateUniqueId';
import deleteIcon from '../../assests/icons/Delete.svg';

function ConfigureTargetSection({
  targetValuePairs,
  setTargetValuePairs,
  reloadDropdownOptions,
  menuOptions,
}) {
  const handleAddPair = () => {
    setTargetValuePairs((prev) => [...prev, {
      id: generateUniqueID(),
      target: '',
      value: '',
    }]);
  };

  const handleDelete = (target) => {
    setTargetValuePairs((prev) => prev.filter((pair) => pair.target !== target));
  };

  const handleTargetChangeForPair = (item, pairId) => {
    setTargetValuePairs((prev) => prev.map((pair) => (
      pair.id === pairId ? { ...pair, target: item.value } : pair
    )));
  };

  const handleValueChangeForPair = (item, pairId) => {
    setTargetValuePairs((prev) => prev.map((pair) => (
      pair.id === pairId ? { ...pair, value: item.value } : pair
    )));
  };

  return (
    <div className="configure-event__section">
      <div className="configure-event__section-container">
        <div className="configure-event__section__header-content">
          Configure Target
        </div>

        {targetValuePairs && targetValuePairs.map((pair) => (
          <div key={pair.id} className="configure-event__control-row">
            <div className="configure-event__control-group-target-dropdown">
              <DropDown
                items={{ items: reloadDropdownOptions }}
                onChange={(item) => handleTargetChangeForPair(item, pair.id)}
                noSelectLabel="Select Target"
                defaultValue={pair.target}
                allowCustomInput
                textBoxPlaceholder="value"
                maxLength={30}
              />
            </div>

            <div className="configure-event__control-group-target-dropdown">
              <DropDown
                items={menuOptions}
                onChange={(item) => handleValueChangeForPair(item, pair.id)}
                noSelectLabel="Select Value"
                defaultValue={pair.value}
                allowCustomInput
                textBoxPlaceholder="value"
                maxLength={30}
              />
            </div>
            <button
              type="button"
              className="configure-event__section__delete-button"
              onClick={() => handleDelete(pair.target)}
            >
              <img className="configure-event__section__delete-icon" src={deleteIcon} alt="delete" />
            </button>
          </div>
        ))}
      </div>
      <button
        type="button"
        className="configure-event__section__add-button"
        onClick={handleAddPair}
      >
        <img className="configure-event__section__add-icon" src={newAddIcon} alt="add" />
        <div className="configure-event__section__add-button-text">Add Component</div>
      </button>
    </div>
  );
}

ConfigureTargetSection.propTypes = {
  targetValuePairs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    target: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  setTargetValuePairs: PropTypes.func.isRequired,
  reloadDropdownOptions: PropTypes.array.isRequired,
  menuOptions: PropTypes.object.isRequired,
};

export default ConfigureTargetSection;
