import PropTypes from 'prop-types';
import { get } from 'lodash';
import JsonKeyValueEditor from './Common/JsonKeyValueEditor';
import CssBuilder from './Common/CssBuilder';
import JsonEditor from './Common/JsonEditor';
import FormModuleEventsV2Panel from './FormModuleEventsV2Panel';

function FormModuleGenericEditorV2(props) {
  const {
    component,
    componentId,
    handleComponentPropertyChange,
    eventDropdownOptions,
    reloadDropdownOptions,
    propsToRender,
    selectedModuleId,
    refreshMap,
  } = props;
  const handleOnChange = (prop, value) => {
    handleComponentPropertyChange(prop, value);
  };

  const getCurrentValue = (comp, prop) => get(comp, prop);

  return (
    <div
      className="edit-properties-div__property"
    >
      {
      (propsToRender || []).map(({
        label, property, type, rootName,
        disableAddButton = false,
        hideDeleteButton = false,
      }) => {
        switch (type) {
          case 'jsonBuilder':
            return (
              <JsonKeyValueEditor
                key={`${componentId}.${property}`}
                label={label}
                onChange={(value) => {
                  handleOnChange(property, value);
                }}
                defaultValue={getCurrentValue(component, property)}
                disableAddButton={disableAddButton}
                hideDeleteButton={hideDeleteButton}
              />
            );
          case 'cssEditor':
            return (
              <CssBuilder
                key={`${componentId}.${property}`}
                label={label}
                onChange={(value) => {
                  handleOnChange(property, value);
                }}
                defaultValue={getCurrentValue(component, property) || `#${componentId} {}`}
              />
            );
          case 'jsonEditor':
            return (
              <JsonEditor
                label={label}
                key={`${componentId}.${property}`}
                onChange={(value) => {
                  handleOnChange(property, value);
                }}
                defaultValue={getCurrentValue(component, property)}
                readOnly={false}
                placeholder="add properties in JSON here"
                rootName={rootName}
              />
            );
          case 'eventsV2List':
            return (
              <FormModuleEventsV2Panel
                refreshMap={refreshMap}
                events={getCurrentValue(component, property) || {}}
                eventDropdownOptions={eventDropdownOptions}
                reloadDropdownOptions={reloadDropdownOptions}
                componentId={componentId}
                selectedModuleId={selectedModuleId}
              />
            );
          default:
            return null;
        }
      })
    }
    </div>
  );
}

FormModuleGenericEditorV2.propTypes = {
  component: PropTypes.object.isRequired,
  componentId: PropTypes.string.isRequired,
  eventDropdownOptions: PropTypes.array.isRequired,
  reloadDropdownOptions: PropTypes.array.isRequired,
  handleComponentPropertyChange: PropTypes.func.isRequired,
  propsToRender: PropTypes.array.isRequired,
  selectedModuleId: PropTypes.string.isRequired,
  refreshMap: PropTypes.object.isRequired,
};

export default FormModuleGenericEditorV2;
