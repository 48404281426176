import * as Sentry from '@sentry/react';

import logBatchedEvents from '../events/logBatchedEvents';
import { clearEventQueue } from '../reducers/events';
import { updateAppIdKeyToken } from '../reducers/user';

const MAX_BATCH_SIZE = process.env.REACT_APP_EVENT_BATCH_SIZE || 5;
const BATCH_INTERVAL = process.env.REACT_APP_EVENT_BATCH_MAX_INTERVAL || 30000;

const flushEvents = async (store) => {
  const state = store.getState();
  const { eventQueue } = state.events;
  const { appIdKeyToken, currentAppId } = state.user;

  const eventCount = eventQueue.length;

  if (eventCount === 0) return;

  const eventQueueToLog = eventQueue.slice(0, eventCount);

  // TODO: remove this once we have a proper way to update the token
  const updateTokenInStore = (token) => store.dispatch(updateAppIdKeyToken({ token }));

  try {
    // Push all current events to the backend
    await logBatchedEvents({
      eventQueue: eventQueueToLog,
      demoToken: appIdKeyToken,
      appId: currentAppId,
      updateTokenInStore,
      retryCount: 0,
    });
  } catch (error) {
    Sentry.captureException(error);
  } finally {
    store.dispatch(clearEventQueue({ eventCount }));
  }
};

const eventBatchingMiddleware = (store) => {
  let flushInProgress = false;

  return (next) => (action) => {
    next(action);
    if (action.type !== 'events/pushEvent') return;

    const state = store.getState();
    const { eventQueue } = state.events;

    // Push immediately if MAX_BATCH_SIZE is reached.
    if (eventQueue.length >= MAX_BATCH_SIZE) {
      if (flushInProgress) return;
      flushInProgress = true;
      flushEvents(store).finally(() => {
        flushInProgress = false;
      });
    }
    // Set up a timer to flush events every BATCH_INTERVAL.
    setInterval(() => {
      if (flushInProgress) return;
      flushInProgress = true;
      flushEvents(store).finally(() => {
        flushInProgress = false;
      });
    }, BATCH_INTERVAL);
  };
};

export default eventBatchingMiddleware;
