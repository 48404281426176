import {
  useState, useRef, useEffect, useContext,
} from 'react';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import './FormModuleEventsV2Panel.scss';
import addIcon from '../../assests/icons/addIconButton.svg';
import deleteIcon from '../../assests/icons/redDeleteIcon.svg';
import { updateWorkflowInState } from '../../workflowOperations/updateWorkflow';
import generateUniqueID from '../../utils/generateUniqueId';
import { preProcessEvents } from '../../workflowOperations/operations/formV2Operations';
import EventList from './EventsV2List';
import ErrorHandlerContext from '../../context/ErrorHandlerContext';

function FormModuleEventsV2Panel({
  events,
  eventDropdownOptions,
  reloadDropdownOptions,
  selectedModuleId,
  refreshMap,
  componentId,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState(null);
  const [deleteMenuAnchorEl, setDeleteMenuAnchorEl] = useState(null);
  const [isPreProcessed, setIsPreProcessed] = useState(false);
  const [showEventsList, setShowEventsList] = useState(false);
  const addButtonRef = useRef(null);
  const [processedEvents, setProcessedEvents] = useState({});
  const handleError = useContext(ErrorHandlerContext);
  // Extract available events from the nested structure
  const extractedEvents = eventDropdownOptions?.[0]?.items?.map((item) => item.value) || [];

  const handleDeleteMenuClose = () => {
    setDeleteMenuAnchorEl(null);
  };
  useEffect(() => {
    const handleClickAway = (event) => {
      if (deleteMenuAnchorEl &&
          !deleteMenuAnchorEl.contains(event.target) &&
          !event.target.closest('.events-panel__delete-menu-option')) {
        handleDeleteMenuClose();
      }
    };

    document.addEventListener('mousedown', handleClickAway);
    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, [deleteMenuAnchorEl]);

  useEffect(() => {
    const updatedEvents = preProcessEvents(events);
    setProcessedEvents(updatedEvents);
    setIsPreProcessed(true);
  }, [events]);

  useEffect(() => {
    const handleClickAway = (event) => {
      if (showEventsList &&
          addButtonRef.current &&
          !addButtonRef.current.contains(event.target) &&
          !event.target.closest('.events-dropdown')) {
        setShowEventsList(false);
      }
    };

    document.addEventListener('mousedown', handleClickAway);
    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, [showEventsList]);

  const handleButtonClick = (eventId, eventData, eventType) => {
    const { refresh, ...otherActions } = eventData?.actions || {};
    setSelectedEventData({ eventId, eventData: { actions: otherActions }, eventType });
    setIsModalOpen(true);
  };

  const handleClose = (eventsV2) => {
    setIsModalOpen(false);
    const updatedEventsV2 = {
      ...processedEvents,
      ...eventsV2,
    };
    try {
      updateWorkflowInState({}, true, {
        operation: 'SET_EVENTS_V2',
        actionData: {
          selectedModuleId,
          eventsV2WithIds: updatedEventsV2,
          refreshMap,
          componentId,
        },
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleAddEventClick = () => {
    setShowEventsList(true);
  };

  const handleAddEvent = (eventId) => {
    // Check if the event type is already present
    const isEventAlreadyPresent = Object.values(processedEvents).some(
      (event) => event[eventId],
    );

    if (isEventAlreadyPresent) {
      return;
    }

    const generatedEventId = generateUniqueID();

    setProcessedEvents({
      ...processedEvents,
      [generatedEventId]: {
        [eventId]: {
          actions: {},
        },
      },
    });

    setShowEventsList(false);
    setSelectedEventData({
      eventId: generatedEventId,
      eventData: {
        actions: {},
      },
      eventType: eventId,
    });
    setIsModalOpen(true);
  };

  const handleMoreButtonClick = (event, eventId, eventData, eventType) => {
    event.stopPropagation();
    setDeleteMenuAnchorEl(event.currentTarget);
    setSelectedEventData({ eventId, eventData, eventType });
  };

  const handleDeleteEvent = () => {
    const eventId = selectedEventData?.eventId;
    const eventTypeToDelete = selectedEventData?.eventType;

    if (!eventId || !eventTypeToDelete) return;
    const updatedEvents = { ...processedEvents };
    delete updatedEvents[eventId];
    setProcessedEvents(updatedEvents);
    try {
      updateWorkflowInState({}, true, {
        operation: 'SET_EVENTS_V2',
        actionData: {
          selectedModuleId,
          eventsV2WithIds: updatedEvents,
          refreshMap,
          componentId,
        },
      });
    } catch (error) {
      handleError(error);
    }

    handleDeleteMenuClose();
  };

  return isPreProcessed ? (
    <div className="events-panel">
      <div className="events-panel__header">
        <div className="events-panel__header-title">Events V2</div>
        <button
          type="button"
          className="events-panel__add-event-button"
          onClick={handleAddEventClick}
          ref={addButtonRef}
        >
          <img src={addIcon} alt="Add event" />
        </button>
      </div>
      {showEventsList && (
        <div className="events-dropdown">
          {extractedEvents
            .filter((eventType) => !Object.values(processedEvents)
              .some((event) => event[eventType]))
            .map((eventType) => (
              <button
                type="button"
                key={eventType}
                className="event-option"
                onClick={() => handleAddEvent(eventType)}
              >
                {eventType}
              </button>
            ))}
          <button
            type="button"
            className="event-option"
            onClick={() => handleAddEvent(`custom${generateUniqueID()}`)}
          >
            Custom Input
          </button>
        </div>
      )}
      { Object.keys(processedEvents || {}).length > 0 && (
        <EventList
          processedEvents={processedEvents}
          handleButtonClick={handleButtonClick}
          handleMoreButtonClick={handleMoreButtonClick}
          isComponentOpen={isModalOpen}
          selectedEventData={selectedEventData}
          eventDropdownOptions={eventDropdownOptions}
          reloadDropdownOptions={reloadDropdownOptions}
          handleClose={handleClose}
          setSelectedEventData={setSelectedEventData}
        />
      )}
      <Popper
        open={Boolean(deleteMenuAnchorEl)}
        anchorEl={deleteMenuAnchorEl}
        placement="bottom-start"
      >
        <div className="events-panel__delete-menu">
          <button
            type="submit"
            className="events-panel__delete-menu-option"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteEvent();
            }}
          >
            <img src={deleteIcon} alt="Delete" />
            <span>Delete</span>
          </button>
        </div>
      </Popper>
    </div>
  ) : null;
}

export default FormModuleEventsV2Panel;

FormModuleEventsV2Panel.propTypes = {
  events: PropTypes.object.isRequired,
  eventDropdownOptions: PropTypes.array.isRequired,
  reloadDropdownOptions: PropTypes.array.isRequired,
  selectedModuleId: PropTypes.string.isRequired,
  refreshMap: PropTypes.object.isRequired,
  componentId: PropTypes.string.isRequired,
};
