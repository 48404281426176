import axios from 'axios';
import HVError, { errorCodes } from './error';

const getDemoToken = async (appId) => {
  try {
    const userRes = await axios({
      method: 'GET',
      headers: { appid: appId || '55a3a6' },
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/demoToken`,
    });
    const { token } = userRes.data.result;
    return token;
  } catch (err) {
    throw new HVError({
      errorCode: errorCodes.errorFetchingAppIdToken,
      message: 'Error fetching app id token',
      originalError: err,
    });
  }
};

export default getDemoToken;
